import {
  Injectable,
} from '@angular/core';
import {
  SearchResponse,
  CountParams,
  CountResponse,
  DeleteDocumentByQueryResponse,
  DeleteDocumentResponse,
  CreateDocumentResponse,
} from 'elasticsearch';
import {
  Action,
  Store,
} from '@ngrx/store';
import {
  Observable,
  of,
} from 'rxjs';
import {
  mergeMap,
  withLatestFrom,
  map,
  switchMap,
} from 'rxjs/operators';

import {
  IDataRequestParams,
  TvcDataModelService,
  IQueryFilterModel,
  switchResponse,
  ISwitchResponse,
  ITryCatchError,
  TvcUserNotificationService,
  TvcDataStateService,
  TvcMessageHubService,
  TvcAuthService,
  TvcLoadingService,
  enDataModelFilterTypes,
  enDataState,
  IFilterModel,
  composeQueryFilters,
  IAggregationResponse,
  getKeysList,
  IDmSearchBody,
  AGGREGATION_MAX_SIZE,
  enLoadingState,
  IElasticSearchDocument,
  BulkChangeDataStateSuccessAction,
} from 'ui-components';

import {
  LoadDataAction,
  ILoadDataSuccessPayload,
  LoadDataSuccessAction,
  ILoadDataFailurePayload,
  LoadDataFailureAction,
  LoadSelectionSummaryAction,
  ILoadSelectionSummarySuccessPayload,
  LoadSelectionSummarySuccessAction,
  ILoadSelectionSummaryFailurePayload,
  LoadSelectionSummaryFailureAction,
  CreateCampaignAction,
  ICreateCampaignPayload,
  ICreateCampaignSuccessPayload,
  CreateCampaignSuccessAction,
  ICreateCampaignFailurePayload,
  CreateCampaignFailureAction,
  StartBulkDeleteCampaignAction,
  IStartBulkDeleteCampaignPayload,
  StartBulkDeleteCampaignSuccessAction,
  IStartBulkDeleteCampaignSuccessPayload,
  IStartBulkDeleteCampaignFailurePayload,
  StartBulkDeleteCampaignFailureAction,
  BulkDeleteCampaignAction,
  IBulkDeleteCampaignSuccessPayload,
  IBulkDeleteCampaignFailurePayload,
  BulkDeleteCampaignFailureAction,
  DeleteCampaignAction,
  IDeleteCampaignPayload,
  IDeleteCampaignSuccessPayload,
  DeleteCampaignSuccessAction,
  IDeleteCampaignFailurePayload,
  DeleteCampaignFailureAction
} from './store/vw-md-campaigns.actions';
import {
  getCampaignsDataGridQueryParams,
  getCampaignsDataGridFilterCondition,
} from './store/vw-md-campaigns.selectors';
import {
  ICampaignsState,
} from './store/vw-md-campaigns.state';
import {
  ICampaignDocument,
  CampaignFieldMapping,
  ICampaign,
  ICampaignsSummary,
  ICampaignsSummaryResponse,
  ISelectionSummary,
  IBulkActionSummary,
  IBulkActionSummaryResponse,
} from './vw-md-campaigns.model';

@Injectable({
  providedIn: 'root'
})
export class VwMdCampaignsService extends TvcDataModelService {

  //#region [Local Variables]

  public readonly indexName = 'idx_campaigns_items';

  public readonly documentType = '_doc';

  public fieldMapping: any = new CampaignFieldMapping();

  public notificationService: TvcUserNotificationService<ICampaign, ICampaignDocument>;

  public dataStateService: TvcDataStateService<ICampaign, ICampaignDocument>;

  //#endregion

  //#region [Properties]

  public get summaryAggregation() {
    return {
      totalItemsCount: {
        value_count: {
          field: '_id'
        }
      },
      totalBudget: {
        sum: {
          field: 'campaign_item_budget'
        }
      }
    };
  }

  /**
   * Gets bulk action summary query
   */
  public get bulkActionSummaryQuery(): IDmSearchBody {
    return {
      size: 0,
      query: {},
      aggs: {
        selectedDocumentIds: {
          terms: {
            field: '_id',
            size: AGGREGATION_MAX_SIZE
          }
        },
      },
      options: {
        parseOptions: {
          parseAggs: false,
          parseFilter: true,
          parseSorts: true
        }
      }
    };
  }

  /**
   * Gets unlocked data state filter
   */
  public get unlockedDataStateFilter(): IQueryFilterModel {
    return {
      dataState: {
        filterType: 'text',
        type: enDataModelFilterTypes.equals,
        filter: [enDataState.failed, enDataState.idle]
      }
    };
  }

  //#endregion

  //#region [Initialization]

  constructor(
    public store: Store<ICampaignsState>,
    private messageHubService: TvcMessageHubService,
    private authService: TvcAuthService,
    private loadingService: TvcLoadingService,
  ) {
    super();
    this.initialize();
    this.setupNotificationService();
    this.setupDataStateService();
  }

  /**
   * Setups notification service
   */
  private setupNotificationService() {
    this.notificationService = new TvcUserNotificationService(
      this.dmParser,
      this.messageHubService,
      this.authService
    );
  }

  /**
   * Setups data state service
   */
  private setupDataStateService() {
    this.dataStateService = new TvcDataStateService(
      this.authService
    );

    this.dataStateService.setup(
      this,
      this.notificationService
    );
  }

  //#endregion

  //#region [Private Methods]

  private prepareLoadDataQuery(request: IDataRequestParams): Observable<IDataRequestParams> {
    if (!request.sort.length) {
      request.sort.push({ colId: '_id', sort: 'asc' });
    }
    request._source = this.displayedFields;
    request.esAggs = Object.assign(
      request.esAggs || {},
      this.summaryAggregation
    );
    const aggQuery: IQueryFilterModel = request.aggQuery as IQueryFilterModel;
    return this.applyContextFilterInQuery(request, aggQuery);
  }

  /**
   * Composes summary
   * @param response
   * @returns summary
   */
  private composeSummary(response: SearchResponse<ICampaignDocument>): ICampaignsSummary {
    try {
      const aggs: ICampaignsSummaryResponse = response.aggregations;

      const summary: ICampaignsSummary = {
        totalItemsCount: aggs.totalItemsCount.value,
        totalBudget: aggs.totalBudget.value
      };
      return summary;

    } catch (error) {
      return {
        totalItemsCount: 0,
        totalBudget: 0
      };
    }
  }

  /**
   * Composes data request params
   * @param filters
   * @returns data request params
   */
  private composeDataRequestParams(
    ...filters: Observable<{ [key: string]: IFilterModel }>[]
  ): Observable<IDataRequestParams> {
    return of({}).pipe(
      withLatestFrom(this.store.select(getCampaignsDataGridQueryParams)),
      map(([_, params]) => params),
      withLatestFrom(
        ...this.contextFilters,
        ...filters
      ),
      composeQueryFilters(),
      map((request: IDataRequestParams) => request)
    );
  }

  private getBulkActionSummary(params: IStartBulkDeleteCampaignPayload): Observable<IBulkActionSummary> {
    return this.applyContextFilterInQuery(
      this.bulkActionSummaryQuery,
      params.selectionFilter,
      this.store.select(getCampaignsDataGridFilterCondition)
    ).pipe(
      switchMap((request: IDataRequestParams) => {
        return this.searchDataModel(request).pipe(
          map((response: any) => {
            return this.composeBulkActionSummaryResult(response, params.selectionFilter);
          })
        );
      })
    );
  }

  private composeBulkActionSummaryResult(
    response: IAggregationResponse<IBulkActionSummaryResponse>,
    selectionFilter: IQueryFilterModel
  ): IBulkActionSummary {

    const aggs: IBulkActionSummaryResponse = response.aggregations;

    const summary: any = {
      selectionFilter,
      selectedItemsCount: aggs.selectedDocumentIds.buckets.length,
      selectedDocumentIds: getKeysList(aggs.selectedDocumentIds.buckets),
    };

    return summary;
  }

  //#endregion

  //#region [Actions]

  //#region [Summary]

  public loadDataAction(action: LoadDataAction): Observable<any> {
    const parser = (data: any) => this.dmParser.fromEs(data);
    return this.prepareLoadDataQuery(action.payload.request).pipe(
      mergeMap((request: IDataRequestParams) => {
        return this.searchDataModel(request).pipe(
          switchResponse(request, { parser })
        );
      })
    );
  }

  public dispatchLoadDataSuccess(source: ISwitchResponse<ICampaignDocument>): Action {
    const summary: ICampaignsSummary = this.composeSummary(source.response);
    const payload: ILoadDataSuccessPayload = { summary };
    return new LoadDataSuccessAction(payload);
  }

  public dispatchLoadDataFailure(failure: ITryCatchError<LoadDataAction, any>): Action {
    failure.action.payload.request.failCallback();
    const failurePayload: ILoadDataFailurePayload = {};
    const failureAction: LoadDataFailureAction = new LoadDataFailureAction(failurePayload);
    return failureAction;
  }

  //#endregion

  //#region [Selection summary]

  /**
   * Counts selected items action
   * @param action
   * @returns selected items action
   */
  public loadSelectionSummaryAction(action: LoadSelectionSummaryAction): Observable<any> {
    return this.composeDataRequestParams(
      of(action.payload.selectionFilter),
    ).pipe(
      mergeMap((request: IDataRequestParams) => {
        const query: any = this.dmParser.toEsFilters(request.query);
        const params: CountParams = {
          index: this.indexName,
          type: this.documentType,
          body: { query }
        };
        return this.count(params);
      })
    );
  }

  /**
   * Dispatches count selected items success
   * @param response
   * @returns count selected items success
   */
  public dispatchLoadSelectionSummarySuccess(response: CountResponse): Action {
    const summary: ISelectionSummary = { selectedItemsCount: response.count };
    const payload: ILoadSelectionSummarySuccessPayload = { summary };
    const action: LoadSelectionSummarySuccessAction = new LoadSelectionSummarySuccessAction(payload);
    return action;
  }

  /**
   * Dispatches count selected items failure
   * @param failure
   * @returns count selected items failure
   */
  public dispatchLoadSelectionSummaryFailure(failure: ITryCatchError<LoadSelectionSummaryAction, any>): Action {
    const payload: ILoadSelectionSummaryFailurePayload = {};
    const action: LoadSelectionSummaryFailureAction = new LoadSelectionSummaryFailureAction(payload);
    return action;
  }

  //#endregion

  //#region [Campaign creation]

  public createCampaignAction(action: CreateCampaignAction): Observable<any> {
    const payload: ICreateCampaignPayload = action.payload;
    const campaignDocument: IElasticSearchDocument<ICampaignDocument> = this.dmParser.toEs(payload.campaignItem, true);

    return this.createDocument(campaignDocument).pipe(
      mergeMap((response: CreateDocumentResponse) => {
        const doc: IElasticSearchDocument<any> = { _id: response._id, ...{} };
        return this.notificationService.notifyRecordCreated(doc).pipe(
          map((notified: boolean) => ({
            ...response
          }))
        );
      })
    );
  }

  public dispatchCreateCampaignSuccess(response: any): Action {
    const successPayload: ICreateCampaignSuccessPayload = {};
    const successAction: CreateCampaignSuccessAction = new CreateCampaignSuccessAction(successPayload);

    return successAction;
  }

  public dispatchCreateCampaignFailure(failure: ITryCatchError<CreateCampaignAction, any>): Action {
    const failurePayload: ICreateCampaignFailurePayload = {};
    const failureAction: CreateCampaignFailureAction = new CreateCampaignFailureAction(failurePayload);

    return failureAction;
  }

  //#endregion

  //#region [load Bulk deletion summary]

  public startBulkDeleteCampaignAction(action: StartBulkDeleteCampaignAction): Observable<any> {
    return this.getBulkActionSummary(action.payload);
  }

  public dispatchStartBulkDeleteCampaignSuccess(bulkActionSummary: IBulkActionSummary): Action {
    const payload: IStartBulkDeleteCampaignSuccessPayload = { bulkActionSummary };
    const action: StartBulkDeleteCampaignSuccessAction = new StartBulkDeleteCampaignSuccessAction(payload);
    return action;
  }

  public dispatchStartBulkDeleteCampaignFailure(failure: ITryCatchError<StartBulkDeleteCampaignAction, any>): Action {
    const payload: IStartBulkDeleteCampaignFailurePayload = {};
    const action: StartBulkDeleteCampaignFailureAction = new StartBulkDeleteCampaignFailureAction(payload);
    return action;
  }

  //#endregion

  //#region [Bulk delete]

  public bulkDeleteCampaignAction(action: BulkDeleteCampaignAction): Observable<any> {
    const selectionFilter: IQueryFilterModel = action.payload.selectionFilter;
    return this.composeDataRequestParams(
      // of(this.unlockedDataStateFilter),
      of(selectionFilter)
    ).pipe(
      mergeMap((request: IDataRequestParams) => {
        return this.notificationService.bulkNotifyRecordDeleted(this, request).pipe(
          mergeMap((notified: boolean) => {
            return this.loadingService.dispatchChangePageLoadStateAction(enLoadingState.success).pipe(
              mergeMap((dispatched: boolean) => {
                return this.deleteDataModelByQuery(request).pipe(
                  map((response: DeleteDocumentByQueryResponse) => {
                    return {
                      selectionFilter,
                      request,
                      ...response,
                    };
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  public dispatchBulkDeleteCampaignSuccess(response: any): Observable<Action> {
    const selectedItemsFilter: IQueryFilterModel = { selectedItems: response.selectionFilter.selectedItems } || {};
    return this.applyFilterInQuery({}, selectedItemsFilter).pipe(
      map((query: IDataRequestParams) => {
        const successPayload: IBulkDeleteCampaignSuccessPayload = {};
        const successAction: BulkChangeDataStateSuccessAction = new BulkChangeDataStateSuccessAction(successPayload);

        return successAction;
      })
    );
  }

  public dispatchBulkDeleteCampaignFailure(failure: ITryCatchError<BulkDeleteCampaignAction, any>): Observable<Action> {
    return this.applyFilterInQuery({}, failure.action.payload.selectionFilter).pipe(
      mergeMap((request: IDataRequestParams) => {
        const payload: IBulkDeleteCampaignFailurePayload = {};
        const action: BulkDeleteCampaignFailureAction = new BulkDeleteCampaignFailureAction(payload);
        return this.dataStateService.combineBulkActionAndNewDataState(action, request, enDataState.failed);
      })
    );
  }

  //#endregion

  //#region [Single delete]

  public deleteCampaignAction(action: DeleteCampaignAction): Observable<any> {
    const payload: IDeleteCampaignPayload = action.payload;
    return this.deleteDataModel(payload.campaignId).pipe(
      mergeMap((response: DeleteDocumentResponse) => {
        const doc: IElasticSearchDocument<any> = { _id: response._id, ...{} };
        return this.notificationService.notifyRecordDeleted(doc).pipe(
          map((notified: boolean) => ({
            ...response
          }))
        );
      })
    );
  }

  public dispatchDeleteCampaignSuccess(response: any): Action {
    const successPayload: IDeleteCampaignSuccessPayload = {};
    const successAction: DeleteCampaignSuccessAction = new DeleteCampaignSuccessAction(successPayload);

    return successAction;
  }

  public dispatchDeleteCampaignFailure(failure: ITryCatchError<DeleteCampaignAction, any>): Action {
    const failurePayload: IDeleteCampaignFailurePayload = {};
    const failureAction: DeleteCampaignFailureAction = new DeleteCampaignFailureAction(failurePayload);

    return failureAction;
  }

  //#endregion


  //#endregion
}
