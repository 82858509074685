
import { newState } from 'ui-components';
import { promotionsInitialState } from './vw-md-promotions.state';
import { vwMdPromotionsActions, enPromotionsActionTypes } from './vw-md-promotions.actions';

export function promotionsReducer(state = promotionsInitialState, action: vwMdPromotionsActions) {
    switch (action.type) {
        case enPromotionsActionTypes.PROMOTION_LOAD_DATA_ACTION: {
            return newState(state, {
                dataGridQueryParams: action.payload.request
            });
        }
        case enPromotionsActionTypes.PROMOTION_LOAD_DATA_SUCCESS_ACTION: {
            return newState(state, {
                summary: action.payload.summary
            });
        }
        case enPromotionsActionTypes.PROMOTION_LOAD_DATA_FAILURE_ACTION: {
            return newState(state, {
                summary: {
                    ...promotionsInitialState.summary,
                }
            });
        }
        default: { return state; }
    }
}
