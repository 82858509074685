import {
    IDistributionNodesSummary
} from '../vw-md-distribution-nodes.model';

import { IDataRequestParams } from 'ui-components';

export interface IDistributionNodesState {
    dataGridQueryParams: IDataRequestParams;
    summary: IDistributionNodesSummary;
}

export const distributionNodesInitialState: IDistributionNodesState = {
    dataGridQueryParams: null,
    summary: {
        totalItemsCount: 0
    },
};
