import {
    IEsFieldAggregationResult,
    ProductFieldMapping,
    IProductDocument,
    enDataState,
    IProductFields,
    ITransactionEntity,
    IElasticSearchDocument,
    IFieldMap,
    TransactionFieldMapping,
} from 'ui-components';

//#region [Elasticsearch documents]

/**
 * Imaster data distribution node document
 */
export interface IMasterDataProductDocument extends IProductDocument {
    id: string;
    product_data_state: enDataState;
    product_locked_user: string;
    product_updated_in: string;
    product_updated_by: string;
    product_created_in: string;
    product_created_by: string;
}

//#endregion

//#region [Model entities]

/**
 * Imaster data distribution node
 */
export interface IMasterDataProduct extends
    IProductFields,
    ITransactionEntity,
    IElasticSearchDocument<IMasterDataProductDocument> {
        id: string;
}

/**
 * Imaster data product
 */
export interface IMasterDataProduct extends IProductFields, ITransactionEntity, IElasticSearchDocument<IMasterDataProductDocument> {
    id: string;
}

/**
 * Iproducts summary
 */
export interface IProductsSummary {
    totalItemsCount: number;
}

/**
 * Iproducts summary response
 */
export interface IProductsSummaryResponse {
    totalItemsCount: IEsFieldAggregationResult;
}

//#endregion

//#region [Classes]

/**
 * Products field mapping
 */
export class MasterDataProductFieldMapping extends ProductFieldMapping {

    //#region [Fields]

    public id: IFieldMap = {
        name: 'id',
        field: '_id',
        asKeyword: false,
        onQuery: false,
    };

    /**
     * Product data rating of product field maps
     */
    public dataRating: IFieldMap = {
        name: this.getFieldNameWithoutPrefix('data_rating'),
        field: this.getFieldWithPrefix('data_rating'),
        asKeyword: false,
        onQuery: true
    };

    private transactionFields: TransactionFieldMapping;

    //#endregion

    //#region [Public Methods]

    /**
     * Creates an instance of distribution node base field mapping.
     * @param [prefix] Prefix for all properties in this class, if applicable.
     * @param [classNestedPropertyName] Name for property containing all of this classes' fields, if applicable.
     */
    constructor(prefix: string = 'product', classNestedPropertyName: string = '', classPrefix: string = '') {
        super(prefix, classNestedPropertyName, classPrefix);
        this.transactionFields = new TransactionFieldMapping(prefix, classNestedPropertyName, classPrefix);
    }

    /**
     * Gets fields
     * @returns fields
     */
    public getFields(): IFieldMap[] {
        return [
            ...super.getFields(),
            ...this.transactionFields.getFields(),
            this.dataRating,
        ];
    }
    //#endregion

}

//#endregion
