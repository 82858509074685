import {
    MemoizedSelector,
    createSelector,
} from '@ngrx/store';
import {
    enLoadingState,
    getPageLoadingState,
    getCurrentStateState,
} from 'ui-components';
import {
    IMasterDataState,
} from './tvc-md.state';

export * from '../vw-md-distribution-nodes/store/vw-md-distribution-nodes.selectors';
export * from '../vw-md-products/store/vw-md-products.selectors';
export * from '../vw-md-supply-details/store/vw-md-supply-details.selectors';
export * from '../vw-md-campaigns/store/vw-md-campaigns.selectors';
export * from '../vw-md-promotions/store/vw-md-promotions.selectors';

// --------------------------------------------------------------------------------------------------- /

export const getMasterDataPageLoadingCurrentState:
    MemoizedSelector<IMasterDataState, enLoadingState> = createSelector(getPageLoadingState, getCurrentStateState);
