import { Action } from '@ngrx/store';
import { IDataRequestParams } from 'ui-components';
import { IDistributionNodesSummary, IMasterDataDistributionNode } from '../vw-md-distribution-nodes.model';

export enum enDistributionNodesActionTypes {

    DISTRIBUTION_NODE_LOAD_DATA_ACTION = '[vw-md-distribution-nodes] Load Data Action',
    DISTRIBUTION_NODE_LOAD_DATA_SUCCESS_ACTION = '[vw-md-distribution-nodes] Load Data Success Action',
    DISTRIBUTION_NODE_LOAD_DATA_FAILURE_ACTION = '[vw-md-distribution-nodes] Load Data Failure Action',

    // -------------------------------------------------------------------------- //

    DISTRIBUTION_NODE_UPDATE_DISTRIBUTION_NODE_ACTION = '[vw-md-distribution-nodes] Update Action',
    DISTRIBUTION_NODE_UPDATE_DISTRIBUTION_NODE_SUCCESS_ACTION = '[vw-md-distribution-nodes] Update Success Action',
    DISTRIBUTION_NODE_UPDATE_DISTRIBUTION_NODE_FAILURE_ACTION = '[vw-md-distribution-nodes] Update Failure Action',

}

//#region [Payloads]

export interface ILoadDataPayload {
    request: IDataRequestParams;
}
export interface ILoadDataSuccessPayload {
    summary: IDistributionNodesSummary;
}
export interface ILoadDataFailurePayload { }

// -------------------------------------------------------------------------- //

export interface IUpdateDistributionNodePayload {
    distributionNodeItem: IMasterDataDistributionNode;
}
export interface IUpdateDistributionNodeSuccessPayload { }
export interface IUpdateDistributionNodeFailurePayload { }

//#endregion

//#region [Actions]

export class LoadDataAction implements Action {
    readonly type = enDistributionNodesActionTypes.DISTRIBUTION_NODE_LOAD_DATA_ACTION;
    constructor(public payload: ILoadDataPayload) { }
}

export class LoadDataSuccessAction implements Action {
    readonly type = enDistributionNodesActionTypes.DISTRIBUTION_NODE_LOAD_DATA_SUCCESS_ACTION;
    constructor(public payload: ILoadDataSuccessPayload) { }
}

export class LoadDataFailureAction implements Action {
    readonly type = enDistributionNodesActionTypes.DISTRIBUTION_NODE_LOAD_DATA_FAILURE_ACTION;
    constructor(public payload: ILoadDataFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class UpdateDistributionNodeAction implements Action {
    readonly type = enDistributionNodesActionTypes.DISTRIBUTION_NODE_UPDATE_DISTRIBUTION_NODE_ACTION;
    constructor(public payload: IUpdateDistributionNodePayload) { }
}

export class UpdateDistributionNodeSuccessAction implements Action {
    readonly type = enDistributionNodesActionTypes.DISTRIBUTION_NODE_UPDATE_DISTRIBUTION_NODE_SUCCESS_ACTION;
    constructor(public payload: IUpdateDistributionNodeSuccessPayload) { }
}
export class UpdateDistributionNodeFailureAction implements Action {
    readonly type = enDistributionNodesActionTypes.DISTRIBUTION_NODE_UPDATE_DISTRIBUTION_NODE_FAILURE_ACTION;
    constructor(public payload: IUpdateDistributionNodeFailurePayload) { }
}

//#endregion

export type vwMdDistributionNodesActions =
    | LoadDataAction
    | LoadDataSuccessAction
    | LoadDataFailureAction

    | UpdateDistributionNodeAction
    | UpdateDistributionNodeSuccessAction
    | UpdateDistributionNodeFailureAction
    ;


