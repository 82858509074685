import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { tryCatch } from 'ui-components';

import { enDistributionNodesActionTypes } from './vw-md-distribution-nodes.actions';
import { VwMdDistributionNodesService } from '../vw-md-distribution-nodes.service';


@Injectable()
export class VwMdDistributionNodesEffects {

    constructor(
        private actions$: Actions,
        private service: VwMdDistributionNodesService
    ) { }

    @Effect()
    public loadData$: any = this.actions$.pipe(
        ofType(enDistributionNodesActionTypes.DISTRIBUTION_NODE_LOAD_DATA_ACTION),
        tryCatch({
            action: (a: any) => this.service.loadDataAction(a),
            success: (r: any) => this.service.dispatchLoadDataSuccess(r),
            failed: (e: any) => this.service.dispatchLoadDataFailure(e)
        })
    );

    @Effect()
    public updateData$: any = this.actions$.pipe(
        ofType(enDistributionNodesActionTypes.DISTRIBUTION_NODE_UPDATE_DISTRIBUTION_NODE_ACTION),
        tryCatch({
            action: (a: any) => this.service.updateDistributionNodeAction(a),
            success: (r: any) => this.service.dispatchUpdateDistributionNodeSuccess(r),
            failed: (e: any) => this.service.dispatchUpdateDistributionNodeFailure(e)
        }),
    );
}
