import {
    updateNestedState,
    enLoadingState,
} from 'ui-components';

import {
    masterDataActions
} from './tvc-md.actions';
import {
    masterDataInitialState
} from './tvc-md.state';
import {
    enSupplyDetailsActionTypes,
} from './../vw-md-supply-details/store/vw-md-supply-details.actions';
import {
    enCampaignsActionTypes,
} from './../vw-md-campaigns/store/vw-md-campaigns.actions';

export function masterDataReducer(state = masterDataInitialState, action: masterDataActions) {
    switch (action.type) {

        case enSupplyDetailsActionTypes.SUPPLY_DETAILS_BULK_DELETE_SUCCESS_ACTION: {
            return updateNestedState(state, 'loading', {
                currentState: enLoadingState.success
            });
        }
        case enSupplyDetailsActionTypes.SUPPLY_DETAILS_BULK_DELETE_FAILURE_ACTION: {
            return updateNestedState(state, 'loading', {
                currentState: enLoadingState.failure
            });
        }
        case enCampaignsActionTypes.CAMPAIGNS_BULK_DELETE_SUCCESS_ACTION: {
            return updateNestedState(state, 'loading', {
                currentState: enLoadingState.success
            });
        }
        case enCampaignsActionTypes.CAMPAIGNS_BULK_DELETE_FAILURE_ACTION: {
            return updateNestedState(state, 'loading', {
                currentState: enLoadingState.failure
            });
        }

        default:
            return state;
    }
}
