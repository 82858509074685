import { IDataRequestParams } from 'ui-components';
import { IPromotionsSummary } from '../vw-md-promotions.model';

export interface IPromotionsState {
    dataGridQueryParams: IDataRequestParams;
    summary: IPromotionsSummary;
}

export const promotionsInitialState: IPromotionsState = {
    dataGridQueryParams: null,
    summary: {
        totalItemsCount: 0,
        totalDiscount: 0
    },
};
