import {
    IMessageHubState,
    IGlobalFilterState,
    IAuthState,
    IDataGridState,
    IDataStateState,
    messageHubInitialState,
    globalFilterInitialState,
    authInitialState,
    dataStateInitialState,
    initialGridState,
    enLoadingState,
    ILoadingState
} from 'ui-components';
import {
    IDistributionNodesState,
    distributionNodesInitialState,
} from './../vw-md-distribution-nodes/store/vw-md-distribution-nodes.state';
import {
    IProductsState,
    productsInitialState,
} from './../vw-md-products/store/vw-md-products.state';
import {
    ISupplyDetailsState,
    supplyDetailsInitialState,
} from './../vw-md-supply-details/store/vw-md-supply-details.state';
import { ICampaignsState, campaignsInitialState } from './../vw-md-campaigns/store/vw-md-campaigns.state';
import { IPromotionsState, promotionsInitialState } from './../vw-md-promotions/store/vw-md-promotions.state';

export interface IMasterDataState {
    messageHub: IMessageHubState;
    // globalFilter: IGlobalFilterState;
    auth: IAuthState;
    dataGrid: IDataGridState;
    dataState: IDataStateState;
    distributionNodes: IDistributionNodesState;
    products: IProductsState;
    supplyDetails: ISupplyDetailsState;
    loading: ILoadingState;
    campaigns: ICampaignsState;
    promotions: IPromotionsState;
}

export const masterDataInitialState: IMasterDataState = {
    messageHub: messageHubInitialState,
    // globalFilter: globalFilterInitialState,
    auth: authInitialState,
    dataGrid: initialGridState,
    dataState: dataStateInitialState,
    distributionNodes: distributionNodesInitialState,
    products: productsInitialState,
    supplyDetails: supplyDetailsInitialState,
    loading: {
        currentState: enLoadingState.idle
    },
    campaigns: campaignsInitialState,
    promotions: promotionsInitialState,
};

