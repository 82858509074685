// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { enAuthProvider, enGlobalFilterProvider } from 'ui-components';

export const environment = {
  appVersion: 'v3.40.0',
  production: false,
  gTag: {
    trackingId: 'UA-148901811-1',
    debug: true,
    trackPageviews: true,
  },
  auth: {
    routes: {
      securityApiEndPoint: 'https://security.services.seeb4.com.br/prod/',
      loginUrl: 'http://localhost:4200/login',
      startRoute: 'demo'
    },
    allowedPaths: ['/uat', '/prod/forgot-password'],
    provider: enAuthProvider.aws
  },
  elastic: {
    routes: {
      serverApiEndPoint: 'https://security.services.seeb4.com.br/prod/elastic'
    }
  },
  globalFilter: {
    provider: enGlobalFilterProvider.masterData
  },
  messageHub: {
    retrySend: false,
    attemptsNumber: 3,
  },
  sidebarOptions: {
    endpoints: {
      masterData: 'http://prod.masterdata.seeb4.com.br/',
      perfectOrder: 'http://prod.perfectorder.seeb4.com.br/'
    },
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
