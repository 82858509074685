import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import {
  IDataRequestParams,
  IQueryFilterModel,
  IModalWindowState,
} from 'ui-components';

import {
  ISupplyDetailsSummary,
  ISelectionSummary,
  IFindSupplyDetailsResult,
  IBulkActionSummary,
  IEditActionSummary,
  IPersistSupplyDetailsResult,
} from '../vw-md-supply-details.model';
import {
  ISupplyDetailsState,
} from './vw-md-supply-details.state';

//#region [Initial state]

export const getSupplyDetailsState: MemoizedSelector<object, ISupplyDetailsState> =
  createFeatureSelector<ISupplyDetailsState>('supplyDetails');

//#endregion

//#region [Internal constants]
// --------------------------------------------------------------------------------------------------- /

const getDataGridQueryParams = (state: ISupplyDetailsState): IDataRequestParams => state.dataGridQueryParams;
const getDataGridFilterCondition = (state: IDataRequestParams): IQueryFilterModel => state.query as IQueryFilterModel;

// --------------------------------------------------------------------------------------------------- /
const getBulkActionSummary = (state: ISupplyDetailsState): IBulkActionSummary => state.bulkActionSummary;

const getSummary = (state: ISupplyDetailsState): ISupplyDetailsSummary => state.summary;
const getSelectionSummary = (state: ISupplyDetailsState): ISelectionSummary => state.selectionSummary;
const getSelectedItemsCount = (state: ISelectionSummary): number => state.selectedItemsCount;
const getSelectionSummaryOnLoading = (state: ISelectionSummary): boolean => state.isLoading;
const getSelectionSummaryOnError = (state: ISelectionSummary): boolean => state.onError;
const getPersistSupplyDetailsActionSummary = (state: ISupplyDetailsState): IEditActionSummary => state.persistSummary;


// --------------------------------------------------------------------------------------------------- /

const getPersistModalState = (state: ISupplyDetailsState): IModalWindowState => state.persistModalState;

// --------------------------------------------------------------------------------------------------- /

const getResult = (state: ISupplyDetailsState): IFindSupplyDetailsResult => state.findSupplyDetailsResult;

const getPersistedResult = (state: ISupplyDetailsState): IPersistSupplyDetailsResult => state.persistSupplyDetailsResult;

const getBulkDeleteSupplyDetailsModalState = (state: ISupplyDetailsState): IModalWindowState => state.bulkDeleteModalState;

//#endregion

//#region [Public selectors]

export const getSupplyDetailsDataGridQueryParams: MemoizedSelector<ISupplyDetailsState, IDataRequestParams> =
  createSelector(getSupplyDetailsState, getDataGridQueryParams);

export const getSupplyDetailsDataGridFilterCondition: MemoizedSelector<any, IQueryFilterModel> =
  createSelector(getSupplyDetailsDataGridQueryParams, getDataGridFilterCondition);

export const getSupplyDetailsBulkActionSummary: MemoizedSelector<ISupplyDetailsState, IBulkActionSummary> =
  createSelector(getSupplyDetailsState, getBulkActionSummary);

export const getSupplyDetailsSummary: MemoizedSelector<ISupplyDetailsState, ISupplyDetailsSummary> =
  createSelector(getSupplyDetailsState, getSummary);

export const getSupplyDetailsSelectionSummary: MemoizedSelector<ISupplyDetailsState, ISelectionSummary> =
  createSelector(getSupplyDetailsState, getSelectionSummary);

export const getSupplyDetailsSelectedItemsCount: MemoizedSelector<ISupplyDetailsState, number> =
  createSelector(getSupplyDetailsSelectionSummary, getSelectedItemsCount);

export const getSupplyDetailsSelectionSummaryOnLoad: MemoizedSelector<ISupplyDetailsState, boolean> =
  createSelector(getSupplyDetailsSelectionSummary, getSelectionSummaryOnLoading);

export const getSupplyDetailsSelectionSummaryOnError: MemoizedSelector<ISupplyDetailsState, boolean> =
  createSelector(getSupplyDetailsSelectionSummary, getSelectionSummaryOnError);

export const getSupplyDetailsBulkReproveModalState: MemoizedSelector<ISupplyDetailsState, IModalWindowState> =
  createSelector(getSupplyDetailsState, getBulkDeleteSupplyDetailsModalState);

// --------------------------------------------------------------------------------------------------- /

export const getSupplyDetailsPersistModalState: MemoizedSelector<ISupplyDetailsState, IModalWindowState> =
  createSelector(getSupplyDetailsState, getPersistModalState);

export const getSupplyDetailsResult: MemoizedSelector<ISupplyDetailsState, IFindSupplyDetailsResult> =
  createSelector(getSupplyDetailsState, getResult);

export const getPersistActionSummary: MemoizedSelector<ISupplyDetailsState, IEditActionSummary> =
  createSelector(getSupplyDetailsState, getPersistSupplyDetailsActionSummary);

export const getPersistedSupplyDetailsResult: MemoizedSelector<ISupplyDetailsState, IPersistSupplyDetailsResult> =
  createSelector(getSupplyDetailsState, getPersistedResult);


//#endregion
