import {
  IDataRequestParams,
  IModalWindowState,
} from 'ui-components';

import {
  ISupplyDetailsSummary,
  ISelectionSummary,
  IFindSupplyDetailsResult,
  IBulkActionSummary,
  IEditActionSummaryResponse,
  IEditActionSummary,
  IPersistSupplyDetailsResult,
} from './../vw-md-supply-details.model';

export interface ISupplyDetailsState {
  dataGridQueryParams: IDataRequestParams;
  summary: ISupplyDetailsSummary;
  selectionSummary: ISelectionSummary;
  persistModalState: IModalWindowState;
  persistSummary: IEditActionSummary;
  findSupplyDetailsResult: IFindSupplyDetailsResult;
  bulkActionSummary: IBulkActionSummary;
  bulkDeleteModalState: IModalWindowState;
  persistSupplyDetailsResult: IPersistSupplyDetailsResult;
}

export const supplyDetailsInitialState: ISupplyDetailsState = {
  dataGridQueryParams: null,
  summary: {
    totalItemsCount: 0,
  },
  selectionSummary: {
    selectedItemsCount: 0,
    isLoading: false,
    onError: false,
  },
  persistModalState: {
    isLoading: false,
    onError: false
  },
  persistSummary: null,
  findSupplyDetailsResult: {
    finded: false,
    resultItem: null,
    isLoading: false,
    onError: false,
  },
  bulkActionSummary: null,
  bulkDeleteModalState: {
    isLoading: false,
    onError: false
  },
  persistSupplyDetailsResult: {
    persisted: false
  }
};
