import { IDataRequestParams } from 'ui-components';
import {
    IProductsSummary
} from '../vw-md-products.model';


export interface IProductsState {
    dataGridQueryParams: IDataRequestParams;
    summary: IProductsSummary;
}

export const productsInitialState: IProductsState = {
    dataGridQueryParams: null,
    summary: {
        totalItemsCount: 0
    },
};
