import { Action } from '@ngrx/store';

import {
    IDataRequestParams,
} from 'ui-components';

import {
    IProductsSummary,
    IMasterDataProduct,
} from '../vw-md-products.model';

export enum enProductsActionTypes {

    PRODUCT_LOAD_DATA_ACTION = '[vw-md-products] Load Data Action',
    PRODUCT_LOAD_DATA_SUCCESS_ACTION = '[vw-md-products] Load Data Success Action',
    PRODUCT_LOAD_DATA_FAILURE_ACTION = '[vw-md-products] Load Data Failure Action',

    // -------------------------------------------------------------------------- //

    PRODUCT_UPDATE_PRODUCT_ACTION = '[vw-md-producs] Update Action',
    PRODUCT_UPDATE_PRODUCT_SUCESS_ACTION = '[vw-md-products] Update Success Action',
    PRODUCT_UPDATE_PRODUCT_FAILURE_ACTION = '[vw-md-products] Update Failure Action'
}

//#region [Payloads]

export interface ILoadDataPayload {
    request: IDataRequestParams;
}
export interface ILoadDataSuccessPayload {
    summary: IProductsSummary;
}
export interface ILoadDataFailurePayload { }

// -------------------------------------------------------------------------- //

export interface IUpdateProductPayload {
    productItem: IMasterDataProduct;
}
export interface IUpdateProductSuccessPayload { }
export interface IUpdateProductFailurePayload { }

//#endregion

//#region [Actions]

export class LoadDataAction implements Action {
    readonly type = enProductsActionTypes.PRODUCT_LOAD_DATA_ACTION;
    constructor(public payload: ILoadDataPayload) { }
}

export class LoadDataSuccessAction implements Action {
    readonly type = enProductsActionTypes.PRODUCT_LOAD_DATA_SUCCESS_ACTION;
    constructor(public payload: ILoadDataSuccessPayload) { }
}

export class LoadDataFailureAction implements Action {
    readonly type = enProductsActionTypes.PRODUCT_LOAD_DATA_FAILURE_ACTION;
    constructor(public payload: ILoadDataFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class UpdateProductAction implements Action {
    readonly type = enProductsActionTypes.PRODUCT_UPDATE_PRODUCT_ACTION;
    constructor(public payload: IUpdateProductPayload) { }
}
export class UpdateProductSuccessAction implements Action {
    readonly type = enProductsActionTypes.PRODUCT_UPDATE_PRODUCT_SUCESS_ACTION;
    constructor(public payload: IUpdateProductSuccessPayload) { }
}
export class UpdateProductFailureAction implements Action {
    readonly type = enProductsActionTypes.PRODUCT_UPDATE_PRODUCT_FAILURE_ACTION;
    constructor(public payload: IUpdateProductFailurePayload) { }
}
//#endregion

export type vwMdProductsActions =
    | LoadDataAction
    | LoadDataSuccessAction
    | LoadDataFailureAction

    | UpdateProductAction
    | UpdateProductSuccessAction
    | UpdateProductFailureAction
    ;
