import { Action } from '@ngrx/store';
import {
  IDataRequestParams,
  IQueryFilterModel,
} from 'ui-components';

import {
  ISupplyDetailsSummary,
  ISelectionSummary,
  IBulkActionSummary,
  ISupplyDetails,
  IEditActionSummary,
  IFindSupplyDetailsResult
} from '../vw-md-supply-details.model';

//#region [Action types]

export enum enSupplyDetailsActionTypes {

  SUPPLY_DETAILS_LOAD_DATA_ACTION = '[vw-md-supply-details] Load Data Action',
  SUPPLY_DETAILS_LOAD_DATA_SUCCESS_ACTION = '[vw-md-supply-details] Load Data Success Action',
  SUPPLY_DETAILS_LOAD_DATA_FAILURE_ACTION = '[vw-md-supply-details] Load Data Failure Action',

  SUPPLY_DETAILS_LOAD_SELECTION_SUMMARY_ACTION = '[vw-md-supply-details] Load Selection Summary Action',
  SUPPLY_DETAILS_LOAD_SELECTION_SUMMARY_SUCCESS_ACTION = '[vw-md-supply-details] Load Selection Summary Success Action',
  SUPPLY_DETAILS_LOAD_SELECTION_SUMMARY_FAILURE_ACTION = '[vw-md-supply-details] Load Selection Summary Failure Action',
  SUPPLY_DETAILS_RESET_SELECTION_SUMMARY_ACTION = '[vw-md-supply-details] Reset Selection Summary Action',

  SUPPLY_DETAILS_START_PERSIST_SUPPLY_DETAILS_ACTION = '[vw-md-supply-details] Start Persist Supply Details Action',
  SUPPLY_DETAILS_START_PERSIST_SUPPLY_DETAILS_SUCCESS_ACTION = '[vw-md-supply-details] Start Persist Supply Details Success Action',
  SUPPLY_DETAILS_START_PERSIST_SUPPLY_DETAILS_FAILURE_ACTION = '[vw-md-supply-details] Start Persist Supply Details Failure Action',

  SUPPLY_DETAILS_PERSIST_SUPPLY_DETAILS_ACTION = '[vw-md-supply-details] Persist Supply Details Action',
  SUPPLY_DETAILS_PERSIST_SUPPLY_DETAILS_SUCCESS_ACTION = '[vw-md-supply-details] Persist Supply Details Success Action',
  SUPPLY_DETAILS_PERSIST_SUPPLY_DETAILS_FAILURE_ACTION = '[vw-md-supply-details] Persist Supply Details Failure Action',

  SUPPLY_DETAILS_FIND_SUPPLY_DETAILS_ACTION = '[vw-md-supply-details] Find Supply Details Action Action',
  SUPPLY_DETAILS_FIND_SUPPLY_DETAILS_SUCCESS_ACTION = '[vw-md-supply-details] Find Supply Details Action Success Action',
  SUPPLY_DETAILS_FIND_SUPPLY_DETAILS_FAILURE_ACTION = '[vw-md-supply-details] Find Supply Details Action Failure Action',
  SUPPLY_DETAILS_RESET_SUPPLY_DETAILS_RESULT_ACTION = '[vw-md-supply-details] Reset Supply Details Action',

  SUPPLY_DETAILS_UPDATE_SUPPLY_DETAILS_ACTION = '[vw-md-supply-details] Update Action',
  SUPPLY_DETAILS_UPDATE_SUPPLY_DETAILS_SUCCESS_ACTION = '[vw-md-supply-details] Update Success Action',
  SUPPLY_DETAILS_UPDATE_SUPPLY_DETAILS_FAILURE_ACTION = '[vw-md-supply-details] Update Failure Action',

  SUPPLY_DETAILS_START_BULK_DELETE_ACTION = '[vw-md-supply-details] Start Bulk Delete Action',
  SUPPLY_DETAILS_START_BULK_DELETE_SUCCESS_ACTION = '[vw-md-supply-details] Start Bulk Delete Success Action',
  SUPPLY_DETAILS_START_BULK_DELETE_FAILURE_ACTION = '[vw-md-supply-details] Start Bulk Delete Failure Action',

  SUPPLY_DETAILS_BULK_DELETE_ACTION = '[vw-md-supply-details] Bulk Delete Action',
  SUPPLY_DETAILS_BULK_DELETE_SUCCESS_ACTION = '[vw-md-supply-details] Bulk Delete Success Action',
  SUPPLY_DETAILS_BULK_DELETE_FAILURE_ACTION = '[vw-md-supply-details] Bulk Delete Failure Action',

  SUPPLY_DETAILS_DELETE_ITEM_ACTION = '[vw-md-supply-details] Delete Item Action',
  SUPPLY_DETAILS_DELETE_ITEM_SUCCESS_ACTION = '[vw-md-supply-details] Delete Item Success Action',
  SUPPLY_DETAILS_DELETE_ITEM_FAILURE_ACTION = '[vw-md-supply-details] Delete Item Failure Action',

}

//#endregion

//#region [Payloads]

export interface ILoadDataPayload {
  request: IDataRequestParams;
}
export interface ILoadDataSuccessPayload {
  summary: ISupplyDetailsSummary;
}
export interface ILoadDataFailurePayload { }

// -------------------------------------------------------------------------- //

export interface ILoadSelectionSummaryPayload {
  selectionFilter: IQueryFilterModel;
}
export interface ILoadSelectionSummarySuccessPayload {
  summary: ISelectionSummary;
}
export interface ILoadSelectionSummaryFailurePayload { }

export interface IResetSelectionSummaryPayload { }

// -------------------------------------------------------------------------- //

export interface IStartPersistSupplyDetailsPayload {}
export interface IStartPersistSupplyDetailsSuccessPayload {
  actionSummary: IEditActionSummary;
}
export interface IStartPersistSupplyDetailsFailurePayload { }

// -------------------------------------------------------------------------- //

export interface IPersistSupplyDetailsPayload {
  supplyDetailsItem: ISupplyDetails;
}
export interface IPersistSupplyDetailsSuccessPayload {}
export interface IPersistSupplyDetailsFailurePayload {}

// -------------------------------------------------------------------------- //

export interface IFindSupplyDetailsPayLoad {
  storeId: string;
  distributionId: string;
  productId: string;
}

export interface IFindSupplyDetailsSuccessPayLoad {
  supplyDetailsResult: IFindSupplyDetailsResult;
}

export interface IFindSupplyDetailsFailurePayLoad {}
export interface IUpdateSupplyDetailsPayload {
  supplyDetailsItem: ISupplyDetails;
}
export interface IResetSupplyDetailsResultPayload { }

// -------------------------------------------------------------------------- //



export interface IUpdateSupplyDetailsSuccessPayload { }
export interface IUpdateSupplyDetailsFailurePayload { }

// -------------------------------------------------------------------------- //

export interface IStartBulkDeleteSupplyDetailsPayload {
  selectionFilter: IQueryFilterModel;
}
export interface IStartBulkDeleteSupplyDetailsSuccessPayload {
  bulkActionSummary: IBulkActionSummary;
}
export interface IStartBulkDeleteSupplyDetailsFailurePayload { }

// -------------------------------------------------------------------------- //

export interface IBulkDeleteSupplyDetailsPayload {
  selectionFilter: IQueryFilterModel;
}
export interface IBulkDeleteSupplyDetailsSuccessPayload { }
export interface IBulkDeleteSupplyDetailsFailurePayload { }

// -------------------------------------------------------------------------- //

export interface IDeleteSupplyDetailsPayload {
  supplyDetailsId: string;
}
export interface IDeleteSupplyDetailsSuccessPayload { }
export interface IDeleteSupplyDetailsFailurePayload { }

//#endregion

//#region [Actions]

export class LoadDataAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_DATA_ACTION;
  constructor(public payload: ILoadDataPayload) { }
}

export class LoadDataSuccessAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_DATA_SUCCESS_ACTION;
  constructor(public payload: ILoadDataSuccessPayload) { }
}

export class LoadDataFailureAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_DATA_FAILURE_ACTION;
  constructor(public payload: ILoadDataFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class LoadSelectionSummaryAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_SELECTION_SUMMARY_ACTION;
  constructor(public payload: ILoadSelectionSummaryPayload) { }
}
export class LoadSelectionSummarySuccessAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_SELECTION_SUMMARY_SUCCESS_ACTION;
  constructor(public payload: ILoadSelectionSummarySuccessPayload) { }
}
export class LoadSelectionSummaryFailureAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_SELECTION_SUMMARY_FAILURE_ACTION;
  constructor(public payload: ILoadSelectionSummaryFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class ResetSelectionSummaryAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_RESET_SELECTION_SUMMARY_ACTION;
  constructor(public payload: IResetSelectionSummaryPayload) { }
}

// ---------------------------------------------------------------- /

export class StartPersistSupplyDetailsAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_PERSIST_SUPPLY_DETAILS_ACTION;
  constructor(public payload: IStartPersistSupplyDetailsPayload) { }
}
export class StartPersistSupplyDetailsSuccessAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_PERSIST_SUPPLY_DETAILS_SUCCESS_ACTION;
  constructor(public payload: IStartPersistSupplyDetailsSuccessPayload) { }
}
export class StartPersistSupplyDetailsFailureAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_PERSIST_SUPPLY_DETAILS_FAILURE_ACTION;
  constructor(public payload: IStartPersistSupplyDetailsFailurePayload) { }
}

// ---------------------------------------------------------------- /
export class PersistSupplyDetailsAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_PERSIST_SUPPLY_DETAILS_ACTION;
  constructor(public payload: IPersistSupplyDetailsPayload) {}
}

export class PersistSupplyDetailsSuccessAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_PERSIST_SUPPLY_DETAILS_SUCCESS_ACTION;
  constructor(public payload: IPersistSupplyDetailsSuccessPayload) {}
}

export class PersistSupplyDetailsFailureAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_PERSIST_SUPPLY_DETAILS_FAILURE_ACTION;
  constructor(public payload: IPersistSupplyDetailsFailurePayload) {}
}

// ---------------------------------------------------------------- /


export class FindSupplyDetailsAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_FIND_SUPPLY_DETAILS_ACTION;
  constructor(public payload: IFindSupplyDetailsPayLoad) { }
}
export class FindSupplyDetailsSuccessAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_FIND_SUPPLY_DETAILS_SUCCESS_ACTION;
  constructor(public payload: IFindSupplyDetailsSuccessPayLoad) { }
}
export class FindSupplyDetailsFailureAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_FIND_SUPPLY_DETAILS_FAILURE_ACTION;
  constructor(public payload: IFindSupplyDetailsFailurePayLoad) { }
}

export class ResetSupplyDetailsResultAction {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_RESET_SUPPLY_DETAILS_RESULT_ACTION;
  constructor(public payload: IResetSupplyDetailsResultPayload) { }
}

// ---------------------------------------------------------------- /

export class UpdateSupplyDetailsAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_UPDATE_SUPPLY_DETAILS_ACTION;
  constructor(public payload: IUpdateSupplyDetailsPayload) { }
}

export class UpdateSupplyDetailsSuccessAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_UPDATE_SUPPLY_DETAILS_SUCCESS_ACTION;
  constructor(public payload: IUpdateSupplyDetailsSuccessPayload) { }
}

export class UpdateSupplyDetailsFailureAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_UPDATE_SUPPLY_DETAILS_FAILURE_ACTION;
  constructor(public payload: IUpdateSupplyDetailsFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class StartBulkDeleteSupplyDetailsAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_BULK_DELETE_ACTION;
  constructor(public payload: IStartBulkDeleteSupplyDetailsPayload) { }
}

export class StartBulkDeleteSupplyDetailsSuccessAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_BULK_DELETE_SUCCESS_ACTION;
  constructor(public payload: IStartBulkDeleteSupplyDetailsSuccessPayload) { }
}

export class StartBulkDeleteSupplyDetailsFailureAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_BULK_DELETE_FAILURE_ACTION;
  constructor(public payload: IStartBulkDeleteSupplyDetailsFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class BulkDeleteSupplyDetailsAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_BULK_DELETE_ACTION;
  constructor(public payload: IBulkDeleteSupplyDetailsPayload) { }
}

export class BulkDeleteSupplyDetailsSuccessAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_BULK_DELETE_SUCCESS_ACTION;
  constructor(public payload: IBulkDeleteSupplyDetailsSuccessPayload) { }
}

export class BulkDeleteSupplyDetailsFailureAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_BULK_DELETE_FAILURE_ACTION;
  constructor(public payload: IBulkDeleteSupplyDetailsFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class DeleteSupplyDetailsAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_DELETE_ITEM_ACTION;
  constructor(public payload: IDeleteSupplyDetailsPayload) { }
}

export class DeleteSupplyDetailsSuccessAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_DELETE_ITEM_SUCCESS_ACTION;
  constructor(public payload: IDeleteSupplyDetailsSuccessPayload) { }
}

export class DeleteSupplyDetailsFailureAction implements Action {
  readonly type = enSupplyDetailsActionTypes.SUPPLY_DETAILS_DELETE_ITEM_FAILURE_ACTION;
  constructor(public payload: IDeleteSupplyDetailsFailurePayload) { }
}

//#endregion

export type vwMdSupplyDetailsActions =
  | LoadDataAction
  | LoadDataSuccessAction
  | LoadDataFailureAction

  | LoadSelectionSummaryAction
  | LoadSelectionSummarySuccessAction
  | LoadSelectionSummaryFailureAction
  | ResetSelectionSummaryAction

  | StartPersistSupplyDetailsAction
  | StartPersistSupplyDetailsSuccessAction
  | StartPersistSupplyDetailsFailureAction

  | PersistSupplyDetailsAction
  | PersistSupplyDetailsSuccessAction
  | PersistSupplyDetailsFailureAction

  | FindSupplyDetailsAction
  | FindSupplyDetailsSuccessAction
  | FindSupplyDetailsFailureAction
  | ResetSupplyDetailsResultAction

  | UpdateSupplyDetailsAction
  | UpdateSupplyDetailsSuccessAction
  | UpdateSupplyDetailsFailureAction

  | StartBulkDeleteSupplyDetailsAction
  | StartBulkDeleteSupplyDetailsSuccessAction
  | StartBulkDeleteSupplyDetailsFailureAction

  | BulkDeleteSupplyDetailsAction
  | BulkDeleteSupplyDetailsSuccessAction
  | BulkDeleteSupplyDetailsFailureAction

  | DeleteSupplyDetailsAction
  | DeleteSupplyDetailsSuccessAction
  | DeleteSupplyDetailsFailureAction

  ;
