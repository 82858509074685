import { newState } from 'ui-components';

import { distributionNodesInitialState } from './vw-md-distribution-nodes.state';
import { vwMdDistributionNodesActions, enDistributionNodesActionTypes } from './vw-md-distribution-nodes.actions';

export function distributionNodesReducer(state = distributionNodesInitialState, action: vwMdDistributionNodesActions) {
    switch (action.type) {

        case enDistributionNodesActionTypes.DISTRIBUTION_NODE_LOAD_DATA_ACTION: {
            return newState(state, {
                dataGridQueryParams: action.payload.request
            });
        }
        case enDistributionNodesActionTypes.DISTRIBUTION_NODE_LOAD_DATA_SUCCESS_ACTION: {
            return newState(state, {
                summary: action.payload.summary
            });
        }
        case enDistributionNodesActionTypes.DISTRIBUTION_NODE_LOAD_DATA_FAILURE_ACTION: {
            return newState(state, {
                summary: {
                    ...distributionNodesInitialState.summary,
                }
            });
        }

        // -------------------------------------------------------------------------- //

        case enDistributionNodesActionTypes.DISTRIBUTION_NODE_UPDATE_DISTRIBUTION_NODE_ACTION: {
            return state;
        }
        case enDistributionNodesActionTypes.DISTRIBUTION_NODE_UPDATE_DISTRIBUTION_NODE_SUCCESS_ACTION: {
            return state;
        }
        case enDistributionNodesActionTypes.DISTRIBUTION_NODE_UPDATE_DISTRIBUTION_NODE_FAILURE_ACTION: {
            return state;

        }
        default: {
            return state;
        }
    }
}

