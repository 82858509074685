import {
    INestedProductDocument,
    INestedDistributionNodeDocument,
    IElasticSearchModel,
    INestedProductFields,
    INestedDistributionNodeFields,
    TransactionFieldMapping,
    IFieldMap,
    DistributionNodeFieldMapping,
    ProductFieldMapping,
    IEsFieldAggregationResult
} from 'ui-components';

export interface IPromotionDocument {
    id: any;
    promotion_classification: string;
    promotion_discount: number;
    promotion_end_date: string;
    promotion_item_end_date: string;
    promotion_item_start_date: string;
    promotion_name: string;
    promotion_start_date: string;
    product: INestedProductDocument;
    distribution_node: INestedDistributionNodeDocument;
}

export interface IPromotion extends IElasticSearchModel<IPromotionDocument> {
    id: string;
    promotionClassification: string;
    promotionDiscount: number;
    promotionEndDate: string;
    promotionItemEndDate: string;
    promotionItemStartDate: string;
    promotionName: string;
    promotionStartDate: string;
    product: INestedProductFields;
    distribution_node: INestedDistributionNodeFields;
}

export interface IPromotionsSummary {
    totalItemsCount: number;
    totalDiscount: number;
}

export interface IPromotionsSummaryResponse {
    totalItemsCount: IEsFieldAggregationResult;
    totalDiscount: IEsFieldAggregationResult;
}

export class PromotionFieldMapping extends TransactionFieldMapping {
    private id: IFieldMap = {
        name: 'id',
        field: '_id',
        asKeyword: false,
        onQuery: false
    };
    private promotionClassification: IFieldMap = {
        name: this.getFieldNameWithoutPrefix('classification'),
        field: this.getFieldWithPrefix('classification'),
        asKeyword: true,
        onQuery: true
    };
    private promotionDiscount: IFieldMap = {
        name: this.getFieldNameWithoutPrefix('discount'),
        field: this.getFieldWithPrefix('discount'),
        asKeyword: false,
        onQuery: true
    };
    private promotionEndDate: IFieldMap = {
        name: this.getFieldNameWithoutPrefix('end_date'),
        field: this.getFieldWithPrefix('end_date'),
        asKeyword: false,
        onQuery: true
    };
    private promotionItemEndDate: IFieldMap = {
        name: this.getFieldNameWithoutPrefix('item_end_date'),
        field: this.getFieldWithPrefix('item_end_date'),
        asKeyword: false,
        onQuery: true
    };
    private promotionItemStartDate: IFieldMap = {
        name: this.getFieldNameWithoutPrefix('item_start_date'),
        field: this.getFieldWithPrefix('item_start_date'),
        asKeyword: false,
        onQuery: true
    };
    private promotionName: IFieldMap = {
        name: this.getFieldNameWithoutPrefix('name'),
        field: this.getFieldWithPrefix('name'),
        asKeyword: true,
        onQuery: true
    };
    private promotionStartDate: IFieldMap = {
        name: this.getFieldNameWithoutPrefix('start_date'),
        field: this.getFieldWithPrefix('start_date'),
        asKeyword: false,
        onQuery: true
    };

    private product: ProductFieldMapping;

    private distributionNode: DistributionNodeFieldMapping;

    constructor() {
        super('promotion');
        this.product = new ProductFieldMapping('', 'product');
        this.distributionNode = new DistributionNodeFieldMapping('', 'distribution_node');
    }

    public getFields(): IFieldMap[] {
        return [
            this.id,
            this.promotionClassification,
            this.promotionDiscount,
            this.promotionEndDate,
            this.promotionItemEndDate,
            this.promotionItemStartDate,
            this.promotionName,
            this.promotionStartDate,
            ...super.getFields(),
            ...this.product.getFields(),
            ...this.distributionNode.getFields(),
        ];
    }
}
