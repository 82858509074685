import { ActionReducerMap, ActionReducer, Action, MetaReducer } from '@ngrx/store';

import {
    authReducer,
    messageHubReducer,
    TvcMessageHubEffects,
    TvcAuthEffects,
    gridReducer,
    TvcAgGridEffects,
    loadingReducer,
    dataStateReducer,
    TvcGlobalFilterEffects,
    globalFilterReducer,
    TvcDataStateEffects,
} from 'ui-components';

import {
    distributionNodesReducer,
} from './../vw-md-distribution-nodes/store/vw-md-distribution-nodes.reducer';
import {
    VwMdDistributionNodesEffects,
} from './../vw-md-distribution-nodes/store/vw-md-distribution-nodes.effects';

import {
    productsReducer,
} from './../vw-md-products/store/vw-md-products.reducer';
import {
    VwMdProductsEffects,
} from './../vw-md-products/store/vw-md-products.effects';

import {
    supplyDetailsReducer,
} from './../vw-md-supply-details/store/vw-md-supply-details.reducer';
import {
    VwMdSupplyDetailsEffects,
} from './../vw-md-supply-details/store/vw-md-supply-details.effects';

import {
    campaignsReducer,
} from './../vw-md-campaigns/store/vw-md-campaigns.reducer';
import {
    VwMdCampaignsEffects,
} from './../vw-md-campaigns/store/vw-md-campaigns.effects';

import {
    IMasterDataState,
} from './tvc-md.state';
import {
    masterDataReducer,
} from './tvc-md.reducer';
import { environment } from 'src/environments/environment';
import { promotionsReducer } from './../vw-md-promotions/store/vw-md-promotions.reducer';
import { VwMdPromotionsEffects } from './../vw-md-promotions/store/vw-md-promotions.effects';

export const reducers: ActionReducerMap<IMasterDataState> = {
    messageHub: messageHubReducer,
    // globalFilter: globalFilterReducer,
    auth: authReducer,
    dataGrid: gridReducer,
    dataState: dataStateReducer,
    distributionNodes: distributionNodesReducer,
    products: productsReducer,
    supplyDetails: supplyDetailsReducer,
    loading: loadingReducer,
    campaigns: campaignsReducer,
    promotions: promotionsReducer,
};


export function globalReducer(reducer: ActionReducer<IMasterDataState>): ActionReducer<IMasterDataState> {
    return (state: IMasterDataState, action: Action): IMasterDataState => {
        const r = (s: IMasterDataState, a: Action) => masterDataReducer(s, a);
        const newState: IMasterDataState = r(state, action);
        return reducer(newState, action);
    };
}

export function logger(reducer: ActionReducer<IMasterDataState>): ActionReducer<IMasterDataState> {
    return (state: IMasterDataState, action: Action): IMasterDataState => {
        console.log('state', state);
        console.log('action', action);
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<IMasterDataState>[] = [globalReducer];
if (environment.production) { metaReducers.push(logger); }


export const effects: any[] = [
    TvcMessageHubEffects,
    // TvcGlobalFilterEffects,
    TvcAuthEffects,
    TvcAgGridEffects,
    TvcDataStateEffects,
    VwMdDistributionNodesEffects,
    VwMdProductsEffects,
    VwMdSupplyDetailsEffects,
    VwMdCampaignsEffects,
    VwMdPromotionsEffects
];
