import {
    IEsFieldAggregationResult,
    enDataState,
    DistributionNodeFieldMapping,
    IFieldMap,
    IDistributionNodeDocument,
    ITransactionEntity,
    IElasticSearchDocument,
    TransactionFieldMapping,
    IDistributionNodeFields,
} from 'ui-components';

//#region [Elasticsearch documents]

/**
 * Imaster data distribution node document
 */
export interface IMasterDataDistributionNodeDocument extends IDistributionNodeDocument {
    id: string;
    distribution_node_data_state: enDataState;
    distribution_node_locked_user: string;
    distribution_node_updated_in: string;
    distribution_node_updated_by: string;
    distribution_node_created_in: string;
    distribution_node_created_by: string;
}

//#endregion

//#region [Model entities]

/**
 * Imaster data distribution node
 */
export interface IMasterDataDistributionNode extends
    IDistributionNodeFields,
    ITransactionEntity,
    IElasticSearchDocument<IMasterDataDistributionNodeDocument> {
        id: string;
}

/**
 * Idistribution nodes summary
 */
export interface IDistributionNodesSummary {
    totalItemsCount: number;
}

/**
 * Idistribution nodes summary response
 */
export interface IDistributionNodesSummaryResponse {
    totalItemsCount: IEsFieldAggregationResult;
}

//#endregion

//#region [Classes]

/**
 * Distribution nodes field mapping
 */
export class MasterDataDistributionNodeFieldMapping extends DistributionNodeFieldMapping {

    //#region [Fields]

    public id: IFieldMap = {
        name: 'id',
        field: '_id',
        asKeyword: false,
        onQuery: false,
    };

    private transactionFields: TransactionFieldMapping;

    //#endregion

    //#region [Public Methods]

    /**
     * Creates an instance of distribution node base field mapping.
     * @param [prefix] Prefix for all properties in this class, if applicable.
     * @param [classNestedPropertyName] Name for property containing all of this classes' fields, if applicable.
     */
    constructor(prefix: string = 'distribution_node', classNestedPropertyName: string = '', classPrefix: string = '') {
        super(prefix, classNestedPropertyName, classPrefix);
        this.transactionFields = new TransactionFieldMapping(prefix, classNestedPropertyName, classPrefix);
    }

    /**
     * Gets fields
     * @returns fields
     */
    public getFields(): IFieldMap[] {
        return [
            ...super.getFields(),
            ...this.transactionFields.getFields(),
        ];
    }

    //#endregion

}

//#endregion
