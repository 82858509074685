import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { tryCatch } from 'ui-components';
import { VwMdCampaignsService } from '../vw-md-campaigns.service';
import { enCampaignsActionTypes } from './vw-md-campaigns.actions';

@Injectable()
export class VwMdCampaignsEffects {
  constructor(
    private actions$: Actions,
    private service: VwMdCampaignsService
  ) { }

  @Effect()
  public loadData$: any = this.actions$.pipe(
    ofType(enCampaignsActionTypes.CAMPAIGNS_LOAD_DATA_ACTION),
    tryCatch({
      action: (a: any) => this.service.loadDataAction(a),
      success: (r: any) => this.service.dispatchLoadDataSuccess(r),
      failed: (e: any) => this.service.dispatchLoadDataFailure(e)
    })
  );

  @Effect()
  public loadSelectionSummary$: any = this.actions$.pipe(
    ofType(enCampaignsActionTypes.CAMPAIGNS_LOAD_SELECTION_SUMMARY_ACTION),
    tryCatch({
      action: (a: any) => this.service.loadSelectionSummaryAction(a),
      success: (r: any) => this.service.dispatchLoadSelectionSummarySuccess(r),
      failed: (e: any) => this.service.dispatchLoadSelectionSummaryFailure(e)
    })
  );

  @Effect()
  public createCampaign$: any = this.actions$.pipe(
    ofType(enCampaignsActionTypes.CAMPAIGNS_CREATE_ITEM_ACTION),
    tryCatch({
      action: (a: any) => this.service.createCampaignAction(a),
      success: (r: any) => this.service.dispatchCreateCampaignSuccess(r),
      failed: (e: any) => this.service.dispatchCreateCampaignFailure(e)
    })
  );

  // @Effect()
  // public editCampaign$: any = this.actions$.pipe(
  //   ofType(enCampaignsActionTypes.CAMPAIGNS_EDIT_ITEM_ACTION),
  //   tryCatch({
  //     action: (a: any) => this.service.editCampaignAction(a),
  //     success: (r: any) => this.service.dispatchEditCampaignSuccess(r),
  //     failed: (e: any) => this.service.dispatchEditCampaignFailure(e)
  //   })
  // );

  @Effect()
  public deleteCampaign$: any = this.actions$.pipe(
    ofType(enCampaignsActionTypes.CAMPAIGNS_DELETE_ITEM_ACTION),
    tryCatch({
      action: (a: any) => this.service.deleteCampaignAction(a),
      success: (r: any) => this.service.dispatchDeleteCampaignSuccess(r),
      failed: (e: any) => this.service.dispatchDeleteCampaignFailure(e)
    }),
  );

  @Effect()
  public startBulkDeleteCampaign$: any = this.actions$.pipe(
    ofType(enCampaignsActionTypes.CAMPAIGNS_START_BULK_DELETE_ACTION),
    tryCatch({
      action: (a: any) => this.service.startBulkDeleteCampaignAction(a),
      success: (r: any) => this.service.dispatchStartBulkDeleteCampaignSuccess(r),
      failed: (e: any) => this.service.dispatchStartBulkDeleteCampaignFailure(e)
    }),
  );

  @Effect()
  public bulkDeleteCampaign$: any = this.actions$.pipe(
    ofType(enCampaignsActionTypes.CAMPAIGNS_BULK_DELETE_ACTION),
    tryCatch({
      action: (a: any) => this.service.bulkDeleteCampaignAction(a),
      success: (r: any) => this.service.dispatchBulkDeleteCampaignSuccess(r),
      failed: (e: any) => this.service.dispatchBulkDeleteCampaignFailure(e)
    }),
  );

}
