import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';

import {
  ISidebarMenuItem,
  getAuthIsAuthenticated,
  User,
  IMessage,
  IWebSocketHeader,
  enProviders,
  enProjectModule,
  enWebSocketActionTypes,
  TvcMessageHubService,
  TvcAuthService,
  enLoadingState,
  TvcLoadingService
} from 'ui-components';

import {
  distributionNodeAccessProfiles,
  productsAccessProfiles,
  supplyDetailsAccessProfiles,
} from './../models/auth-guard';

import {
  getMasterDataPageLoadingCurrentState,
} from '../store/tvc-md.selectors';
import {
  IMasterDataState,
} from '../store/tvc-md.state';

@Component({
  selector: 'app-vw-md-master-page',
  templateUrl: './vw-md-master-page.component.html',
  styleUrls: ['./vw-md-master-page.component.scss']
})
export class VwMdMasterPageComponent implements OnInit, OnDestroy {

  /**
   * Menu items of vw po master data component
   */
  public menuItems: ISidebarMenuItem[] = [];

  /**
   * Subscriptions$  of vw po master data component
   */
  private subscriptions$: Subscription = new Subscription();

  constructor(
    public store: Store<IMasterDataState>,
    private tvcMessageHubService: TvcMessageHubService,
    private authService: TvcAuthService,
    private loadingService: TvcLoadingService,
  ) { }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.loadMenuItems();
    this.listenObservables();
  }

  /**
   * on destroy
   */
  public ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  /**
   * Gets whether is bulk recomputing state$
   */
  public get pageLoadingState$(): Observable<enLoadingState> {
    return this.store.select(getMasterDataPageLoadingCurrentState);
  }

  /**
   * Listens observables
   */
  private listenObservables() {
    this.subscriptions$.add(
      this.store.select(getAuthIsAuthenticated).subscribe(
        (isAuthenticated: boolean) => {
          if (isAuthenticated) {
            this.connectToWebSocket();
          } else {
            this.disconnectToWebSocket();
          }
        }
      )
    );

    this.subscriptions$.add(
      this.pageLoadingState$.subscribe((state: enLoadingState) => {
        switch (state) {
          case enLoadingState.loading:
            this.loadingService.loading();
            break;
          case enLoadingState.success:
            this.loadingService.success();
            this.loadingService.dispatchChangePageLoadStateAction(enLoadingState.idle);
            break;
          case enLoadingState.failure:
            this.loadingService.failure();
            this.loadingService.dispatchChangePageLoadStateAction(enLoadingState.idle);
            break;
        }
      })
    );
  }

  /**
   * connect to webSocket
   * @returns void
   */
  private connectToWebSocket(): void {
    try {
      const user: User = this.authService.getCurrentUser();
      if (user) {
        const userDomain: string = user.domain;
        const payload: IMessage<IWebSocketHeader> = {
          header: {
            provider: enProviders.webSocket,
            subChannels: [],
            projectModule: enProjectModule.perfectOrder,
            userDomain,
            name: 'connectWebSocket',
            channelId: userDomain,
            action: enWebSocketActionTypes.SUBSCRIBE_CHANNEL,
          },
        };
        this.tvcMessageHubService.sendMessage(payload);
      }
    } catch (error) {
      console.error(error);
    }


    // TODO: how to make this signal
    // this.webSocketIsConnected = true;
  }

  /**
   * disconnect to webSocket
   * @returns void
   */
  private disconnectToWebSocket(): void {
    try {
      const user: User = this.authService.getCurrentUser();
      if (user) {
        const userDomain: string = user.domain;
        const payload: IMessage<IWebSocketHeader> = {
          header: {
            provider: enProviders.webSocket,
            subChannels: [],
            projectModule: enProjectModule.perfectOrder,
            userDomain,
            name: 'disconnectWebSocket',
            channelId: userDomain,
            action: enWebSocketActionTypes.UNSUBSCRIBE_CHANNEL,
          },
        };

        this.tvcMessageHubService.sendMessage(payload);
      }
    } catch (error) {
      console.error(error);
    }
    // TODO: how to make this signal
    // this.webSocketIsConnected = false;
  }

  /**
   * Loads menu items
   */
  private loadMenuItems() {
    const user: User = this.authService.getCurrentUser();

    this.menuItems = [
      {
        link: '/master-data/distribution-nodes',
        text: 'Pontos de movimentação',
        icon: 'fa fa-building-o',
        accessProfile: distributionNodeAccessProfiles,
      },
      {
        link: '/master-data/products',
        text: 'Produtos',
        icon: 'fa fa-cube',
        accessProfile: productsAccessProfiles,
      },
      {
        link: '/master-data/supply-details',
        text: 'Detalhes de abastecimento',
        icon: 'fa fa-truck',
        accessProfile: supplyDetailsAccessProfiles,
      },
      // {
      //   link: '/master-data/campaigns',
      //   text: 'Campanhas',
      //   icon: 'fa fa-bullhorn',
      //  // accessProfile: campaignsAccessProfiles,
      // },
      // {
      //   link: '/master-data/promotions',
      //   text: 'Promoções',
      //   icon: 'fa fa-money',
      //   // accessProfile: promotionsAccessProfiles
      // }
    ];
  }
}
