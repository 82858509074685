import {
  IDataRequestParams,
  IModalWindowState,
} from 'ui-components';

import {
  ICampaignsSummary,
  ISelectionSummary,
  IBulkActionSummary,
} from '../vw-md-campaigns.model';

export interface ICampaignsState {
  dataGridQueryParams: IDataRequestParams;
  summary: ICampaignsSummary;
  selectionSummary: ISelectionSummary;
  creationModalState: IModalWindowState;
  bulkActionSummary: IBulkActionSummary;
  bulkDeleteModalState: IModalWindowState;
}

export const campaignsInitialState: ICampaignsState = {
  dataGridQueryParams: null,
  summary: {
    totalItemsCount: 0,
    totalBudget: 0
  },
  selectionSummary: {
    selectedItemsCount: 0,
    isLoading: false,
    onError: false,
  },
  creationModalState: {
    isLoading: false,
    onError: false
  },
  bulkActionSummary: null,
  bulkDeleteModalState: {
    isLoading: false,
    onError: false
  },
};
