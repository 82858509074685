import { Injectable } from '@angular/core';

import {
  SearchResponse,
  CountParams,
  CountResponse,
  DeleteDocumentByQueryResponse,
  DeleteDocumentResponse,
} from 'elasticsearch';
import {
  Store,
  Action,
} from '@ngrx/store';
import {
  Observable,
  of,
} from 'rxjs';
import {
  mergeMap,
  withLatestFrom,
  map,
  switchMap,
} from 'rxjs/operators';

import {
  IDataRequestParams,
  switchResponse,
  ISwitchResponse,
  ITryCatchError,
  TvcDataModelService,
  IQueryFilterModel,
  IFilterModel,
  composeQueryFilters,
  TvcDataStateService,
  TvcAuthService,
  enDataState,
  enLoadingState,
  IElasticSearchDocument,
  IDmSearchBody,
  IAggregationResponse,
  enDataModelFilterTypes,
  AGGREGATION_MAX_SIZE,
  getKeysList,
  TvcLoadingService,
  TvcUserNotificationService,
  TvcMessageHubService,
  User,
  IEsPartUpdateDocumentResponse,
  firstOrDefault,
  isEmpty,
} from 'ui-components';

import {
  LoadDataAction,
  ILoadDataSuccessPayload,
  LoadDataSuccessAction,
  ILoadDataFailurePayload,
  LoadDataFailureAction,
  LoadSelectionSummaryAction,
  ILoadSelectionSummarySuccessPayload,
  LoadSelectionSummarySuccessAction,
  ILoadSelectionSummaryFailurePayload,
  LoadSelectionSummaryFailureAction,
  StartPersistSupplyDetailsAction,
  IStartPersistSupplyDetailsSuccessPayload,
  StartPersistSupplyDetailsSuccessAction,
  IStartPersistSupplyDetailsFailurePayload,
  StartPersistSupplyDetailsFailureAction,
  IStartPersistSupplyDetailsPayload,
  FindSupplyDetailsAction,
  IFindSupplyDetailsPayLoad,
  FindSupplyDetailsSuccessAction,
  IFindSupplyDetailsSuccessPayLoad,
  IStartBulkDeleteSupplyDetailsPayload,
  StartBulkDeleteSupplyDetailsAction,
  IStartBulkDeleteSupplyDetailsSuccessPayload,
  StartBulkDeleteSupplyDetailsSuccessAction,
  IStartBulkDeleteSupplyDetailsFailurePayload,
  StartBulkDeleteSupplyDetailsFailureAction,
  BulkDeleteSupplyDetailsAction,
  IBulkDeleteSupplyDetailsSuccessPayload,
  BulkDeleteSupplyDetailsSuccessAction,
  IBulkDeleteSupplyDetailsFailurePayload,
  BulkDeleteSupplyDetailsFailureAction,
  IDeleteSupplyDetailsPayload,
  DeleteSupplyDetailsAction,
  IDeleteSupplyDetailsSuccessPayload,
  DeleteSupplyDetailsSuccessAction,
  IDeleteSupplyDetailsFailurePayload,
  DeleteSupplyDetailsFailureAction,
  UpdateSupplyDetailsAction,
  IUpdateSupplyDetailsPayload,
  IUpdateSupplyDetailsSuccessPayload,
  UpdateSupplyDetailsSuccessAction,
  IUpdateSupplyDetailsFailurePayload,
  UpdateSupplyDetailsFailureAction,
  FindSupplyDetailsFailureAction,
  PersistSupplyDetailsAction,
  IPersistSupplyDetailsFailurePayload,
  PersistSupplyDetailsFailureAction,
  PersistSupplyDetailsSuccessAction,
  IPersistSupplyDetailsSuccessPayload,
} from './store/vw-md-supply-details.actions';
import {
  getSupplyDetailsDataGridQueryParams, getSupplyDetailsDataGridFilterCondition,
} from './store/vw-md-supply-details.selectors';
import {
  ISupplyDetailsState,
} from './store/vw-md-supply-details.state';

import {
  ISupplyDetailsDocument,
  ISupplyDetailsSummary,
  ISupplyDetailsSummaryResponse,
  ISelectionSummary,
  SupplyDetailsFieldMapping,
  ISupplyDetails,
  IEditActionSummary,
  IEditActionSummaryResponse,
  IFindSupplyDetailsResult,
  IBulkActionSummary,
  IBulkActionSummaryResponse,
} from './vw-md-supply-details.model';

@Injectable({
  providedIn: 'root'
})
export class VwMdSupplyDetailsService extends TvcDataModelService {

  //#region [Local Variables]

  /**
   * Index name of vw md distribution nodes service
   */
  public readonly indexName = 'idx_supply_params';

  /**
   * Document type of vw md distribution nodes service
   */
  public readonly documentType = '_doc';

  /**
   * Field mapping of vw md distribution nodes service
   */
  public fieldMapping: any = new SupplyDetailsFieldMapping();

  /**
   * Notification service of vw po orders service
   */
  public notificationService: TvcUserNotificationService<ISupplyDetails, ISupplyDetailsDocument>;

  /**
   * Data state service of vw po orders service
   */
  public dataStateService: TvcDataStateService<ISupplyDetails, ISupplyDetailsDocument>;

  //#endregion

  //#region [Properties]

  public get summaryAggregation() {
    return {
      totalItemsCount: {
        value_count: {
          field: '_id'
        }
      }
    };
  }

  public get persistSummaryQuery(): IDmSearchBody {
    return {
      size: 0,
      aggs: {
        totalItems: {
          value_count: {
            field: '_id'
          }
        },
        source_count: {
          cardinality: {
            field: 'distribution_node_source_id'
          }
        },
        product_count: {
          cardinality: {
            field: 'product_id'
          }
        },
        store_count: {
          cardinality: {
            field: 'distribution_node_id'
          }
        }
      }
    };
  }

  /**
   * Gets bulk action summary query
   */
  public get bulkActionSummaryQuery(): IDmSearchBody {
    return {
      size: 0,
      query: {},
      aggs: {
        selectedDocumentIds: {
          terms: {
            field: '_id',
            size: AGGREGATION_MAX_SIZE
          }
        },
      },
      options: {
        parseOptions: {
          parseAggs: false,
          parseFilter: true,
          parseSorts: true
        }
      }
    };
  }

  /**
   * Gets unlocked data state filter
   */
  public get unlockedDataStateFilter(): IQueryFilterModel {
    return {
      dataState: {
        filterType: 'text',
        type: enDataModelFilterTypes.equals,
        filter: [enDataState.failed, enDataState.idle]
      }
    };
  }

  /**
   * Gets current user
   */
  public get currentUser(): User {
    return this.authService.getCurrentUser();
  }

  //#endregion

  //#region [Initialization]

  constructor(
    public store: Store<ISupplyDetailsState>,
    private messageHubService: TvcMessageHubService,
    private authService: TvcAuthService,
    private loadingService: TvcLoadingService,
  ) {
    super();

    this.initialize();
    this.setupNotificationService();
    this.setupDataStateService();
  }

  /**
   * Setups notification service
   */
  private setupNotificationService() {
    this.notificationService = new TvcUserNotificationService(
      this.dmParser,
      this.messageHubService,
      this.authService
    );
  }

  /**
   * Setups data state service
   */
  private setupDataStateService() {
    this.dataStateService = new TvcDataStateService(
      this.authService
    );

    this.dataStateService.setup(
      this,
      this.notificationService
    );
  }

  //#endregion

  //#region [Private Methods]

  /**
   * Prepares load data
   * @param request
   * @returns load data
   */
  private prepareLoadDataQuery(request: IDataRequestParams): Observable<IDataRequestParams> {
    if (!request.sort.length) {
      request.sort.push({ colId: '_id', sort: 'asc' });
    }

    request._source = this.displayedFields;

    request.esAggs = Object.assign(
      request.esAggs || {},
      this.summaryAggregation
    );

    const aggQuery: IQueryFilterModel = request.aggQuery as IQueryFilterModel;

    return this.applyContextFilterInQuery(request, aggQuery);
  }

  /**
   * Composes summary
   * @param response
   * @returns summary
   */
  private composeSummary(response: SearchResponse<ISupplyDetailsDocument>): ISupplyDetailsSummary {
    try {
      const aggs: ISupplyDetailsSummaryResponse = response.aggregations;

      const summary: ISupplyDetailsSummary = {
        totalItemsCount: aggs.totalItemsCount.value
      };
      return summary;

    } catch (error) {
      return {
        totalItemsCount: 0
      };
    }
  }

  /**
   * Composes data request params
   * @param filters
   * @returns data request params
   */
  private composeDataRequestParams(
    ...filters: Observable<{ [key: string]: IFilterModel }>[]
  ): Observable<IDataRequestParams> {
    return of({}).pipe(
      withLatestFrom(this.store.select(getSupplyDetailsDataGridQueryParams)),
      map(([_, params]) => params),
      withLatestFrom(
        ...this.contextFilters,
        ...filters
      ),
      composeQueryFilters(),
      map((request: IDataRequestParams) => request)
    );
  }

  /**
   * Gets bulk action summary
   * @param params
   * @returns bulk action summary
   */
  private getBulkActionSummary(params: IStartBulkDeleteSupplyDetailsPayload): Observable<IBulkActionSummary> {
    return this.applyContextFilterInQuery(
      this.bulkActionSummaryQuery,
      params.selectionFilter,
      this.store.select(getSupplyDetailsDataGridFilterCondition)
    ).pipe(
      switchMap((request: IDataRequestParams) => {
        return this.searchDataModel(request).pipe(
          map((response: any) => {
            return this.composeBulkActionSummaryResult(response, params.selectionFilter);
          })
        );
      })
    );
  }

  /**
   * Composes bulk action summary result
   * @param response
   * @returns bulk action summary result
   */
  private composeBulkActionSummaryResult(
    response: IAggregationResponse<IBulkActionSummaryResponse>,
    selectionFilter: IQueryFilterModel
  ): IBulkActionSummary {

    const aggs: IBulkActionSummaryResponse = response.aggregations;

    const summary: any = {
      selectionFilter,
      selectedItemsCount: aggs.selectedDocumentIds.buckets.length,
      selectedDocumentIds: getKeysList(aggs.selectedDocumentIds.buckets),
    };

    return summary;
  }

  //#endregion

  //#region [Actions]

  public startPersistSupplyDetailsAction(action: StartPersistSupplyDetailsAction): Observable<any> {
    return this.getPersistSummary(action.payload);
  }

  /**
   * Dispatches start bulk edition success
   * @param response
   * @returns
   */
  public dispatchStartPersistSupplyDetailsSuccess(persistSummary: IEditActionSummary): Action {
    const payload: IStartPersistSupplyDetailsSuccessPayload = { actionSummary: persistSummary };
    return new StartPersistSupplyDetailsSuccessAction(payload);

  }

  /**
   * Dispatches start bulk edition failure
   * @param error
   * @returns
   */
  public dispatchStartPersistSupplyDetailsFailure(failure: ITryCatchError<IEditActionSummary, any>): Action {
    const payload: IStartPersistSupplyDetailsFailurePayload = {};
    const action: StartPersistSupplyDetailsFailureAction = new StartPersistSupplyDetailsFailureAction(payload);
    return action;
  }

  public persistSupplyDetails(action: PersistSupplyDetailsAction): Observable<any> {
    const supplyDetailsDocument: IElasticSearchDocument<ISupplyDetailsDocument> =
      this.dmParser.toEs(action.payload.supplyDetailsItem, true);

    return this.createDocument(supplyDetailsDocument);
    // return this.dataStateService.updateDataStateAndNotify(supplyDetailsDocument._id, enDataState.editing).pipe(
    //   mergeMap((notified: boolean) => {
    //     return this.createDocument(supplyDetailsDocument);
    //   })
    // );
  }

  public dispatchPersistSuccess(response: IEsPartUpdateDocumentResponse<ISupplyDetailsDocument>): Action {
    const successPayload: IPersistSupplyDetailsSuccessPayload = {};
    const successAction: PersistSupplyDetailsSuccessAction = new PersistSupplyDetailsSuccessAction(successPayload);

    return successAction;
    // return this.dataStateService.combineActionAndNewDataState(successAction, response._id, enDataState.idle);
  }

  public dispatchPersistFailure(failure: ITryCatchError<PersistSupplyDetailsAction, any>): Action {
    const failurePaylod: IPersistSupplyDetailsFailurePayload = {};
    const action: PersistSupplyDetailsFailureAction = new PersistSupplyDetailsFailureAction(failurePaylod);

    return action;

  }



  //#region [Summary]

  /**
   * Loads data action
   * @param action
   * @returns data action
   */
  public loadDataAction(action: LoadDataAction): Observable<any> {
    const parser = (data: any) => this.dmParser.fromEs(data);
    return this.prepareLoadDataQuery(action.payload.request).pipe(
      mergeMap((request: IDataRequestParams) => {
        return this.searchDataModel(request).pipe(
          switchResponse(request, { parser })
        );
      })
    );
  }

  /**
   * Dispatchs load data success
   * @param response
   * @returns
   */
  public dispatchLoadDataSuccess(source: ISwitchResponse<ISupplyDetailsDocument>): Action {
    const summary: ISupplyDetailsSummary = this.composeSummary(source.response);
    const payload: ILoadDataSuccessPayload = { summary };
    return new LoadDataSuccessAction(payload);
  }

  /**
   * Dispatchs load data failure
   * @param error
   * @returns
   */
  public dispatchLoadDataFailure(failure: ITryCatchError<LoadDataAction, any>): Action {

    failure.action.payload.request.failCallback();

    const failurePayload: ILoadDataFailurePayload = {};
    const failureAction: LoadDataFailureAction = new LoadDataFailureAction(failurePayload);

    return failureAction;
  }

  //#endregion

  //#region [Selection summary]

  /**
   * Counts selected items action
   * @param action
   * @returns selected items action
   */
  public loadSelectionSummaryAction(action: LoadSelectionSummaryAction): Observable<any> {
    return this.composeDataRequestParams(
      of(action.payload.selectionFilter),
    ).pipe(
      mergeMap((request: IDataRequestParams) => {
        const query: any = this.dmParser.toEsFilters(request.query);
        const params: CountParams = {
          index: this.indexName,
          type: this.documentType,
          body: { query }
        };
        return this.count(params);
      })
    );
  }

  /**
   * Dispatches count selected items success
   * @param response
   * @returns count selected items success
   */
  public dispatchLoadSelectionSummarySuccess(response: CountResponse): Action {
    const summary: ISelectionSummary = { selectedItemsCount: response.count };
    const payload: ILoadSelectionSummarySuccessPayload = { summary };
    const action: LoadSelectionSummarySuccessAction = new LoadSelectionSummarySuccessAction(payload);
    return action;
  }

  /**
   * Dispatches count selected items failure
   * @param failure
   * @returns count selected items failure
   */
  public dispatchLoadSelectionSummaryFailure(failure: ITryCatchError<LoadSelectionSummaryAction, any>): Action {
    const payload: ILoadSelectionSummaryFailurePayload = {};
    const action: LoadSelectionSummaryFailureAction = new LoadSelectionSummaryFailureAction(payload);
    return action;
  }

  //#endregion

  //#region [Update]

  /**
   * Updates supply details action
   * @param action
   * @returns supply details action
   */
  public updateSupplyDetailsAction(action: UpdateSupplyDetailsAction): Observable<any> {
    const payload: IUpdateSupplyDetailsPayload = action.payload;
    const newState = enDataState.idle;

    return this.updateDataModelByScript(
      payload.supplyDetailsItem,
      newState,
      this.displayedNames,
      this.currentUser.username,
    ).pipe(
      mergeMap((response: IEsPartUpdateDocumentResponse<ISupplyDetailsDocument>) => {
        const doc: IElasticSearchDocument<ISupplyDetailsDocument> = this.dmParser.convertUpdateResponseToDocument(response);
        return this.dataStateService.switchChangeStateNotification(doc, newState);
      })
    );
  }

  /**
   * Dispatchs update supply details success
   * @param response
   * @returns update supply details success
   */
  public dispatchUpdateSupplyDetailsSuccess(response: any): Action[] {
    const successPayload: IUpdateSupplyDetailsSuccessPayload = {};
    const successAction: UpdateSupplyDetailsSuccessAction = new UpdateSupplyDetailsSuccessAction(successPayload);

    return this.dataStateService.combineActionAndNewDataState(successAction, response._id, enDataState.idle);
  }

  /**
   * Dispatches update supply details failure
   * @param failure failure action
   * @returns update supply details failure
   */
  public dispatchUpdateSupplyDetailsFailure(failure: ITryCatchError<UpdateSupplyDetailsAction, any>): Action[] {
    const docId: string = failure.action.payload.supplyDetailsItem.id;
    const failurePayload: IUpdateSupplyDetailsFailurePayload = {};
    const failureAction: UpdateSupplyDetailsFailureAction = new UpdateSupplyDetailsFailureAction(failurePayload);

    return this.dataStateService.combineActionAndNewDataState(failureAction, docId, enDataState.failed);
  }

  //#endregion

  //#region [Load bulk deletion summary]

  public startBulkDeleteSupplyDetailsAction(action: StartBulkDeleteSupplyDetailsAction): Observable<any> {
    return this.getBulkActionSummary(action.payload);
  }

  /**
   * Dispatches start order composition success
   * @param response
   * @returns start order composition success
   */
  public dispatchStartBulkDeleteSupplyDetailsSuccess(bulkActionSummary: IBulkActionSummary): Action {
    const payload: IStartBulkDeleteSupplyDetailsSuccessPayload = { bulkActionSummary };
    const action: StartBulkDeleteSupplyDetailsSuccessAction = new StartBulkDeleteSupplyDetailsSuccessAction(payload);
    return action;
  }

  public dispatchStartBulkDeleteSupplyDetailsFailure(failure: ITryCatchError<StartBulkDeleteSupplyDetailsAction, any>): Action {
    const payload: IStartBulkDeleteSupplyDetailsFailurePayload = {};
    const action: StartBulkDeleteSupplyDetailsFailureAction = new StartBulkDeleteSupplyDetailsFailureAction(payload);
    return action;
  }

  //#endregion

  //#region [Bulk delete]

  public bulkDeleteSupplyDetailsAction(action: BulkDeleteSupplyDetailsAction): Observable<any> {
    const selectionFilter: IQueryFilterModel = action.payload.selectionFilter;
    return this.composeDataRequestParams(
      // of(this.unlockedDataStateFilter),
      of(selectionFilter)
    ).pipe(
      mergeMap((request: IDataRequestParams) => {
        return this.notificationService.bulkNotifyRecordDeleted(this, request).pipe(
          mergeMap((notified: boolean) => {
            return this.loadingService.dispatchChangePageLoadStateAction(enLoadingState.success).pipe(
              mergeMap((dispatched: boolean) => {
                return this.deleteDataModelByQuery(request).pipe(
                  map((response: DeleteDocumentByQueryResponse) => {
                    return {
                      selectionFilter,
                      request,
                      ...response,
                    };
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  public dispatchBulkDeleteSupplyDetailsSuccess(response: any): Observable<Action> {
    const selectedItemsFilter: IQueryFilterModel = { selectedItems: response.selectionFilter.selectedItems } || {};
    return this.applyFilterInQuery({}, selectedItemsFilter).pipe(
      map((query: IDataRequestParams) => {
        const successPayload: IBulkDeleteSupplyDetailsSuccessPayload = {};
        const successAction: BulkDeleteSupplyDetailsSuccessAction = new BulkDeleteSupplyDetailsSuccessAction(successPayload);

        return successAction;
      })
    );
  }

  public dispatchBulkDeleteSupplyDetailsFailure(failure: ITryCatchError<BulkDeleteSupplyDetailsAction, any>): Observable<Action> {
    return this.applyFilterInQuery({}, failure.action.payload.selectionFilter).pipe(
      mergeMap((request: IDataRequestParams) => {
        const payload: IBulkDeleteSupplyDetailsFailurePayload = {};
        const action: BulkDeleteSupplyDetailsFailureAction = new BulkDeleteSupplyDetailsFailureAction(payload);
        return this.dataStateService.combineBulkActionAndNewDataState(action, request, enDataState.failed);
      })
    );
  }

  //#endregion

  //#region [Single delete]

  public deleteSupplyDetailsAction(action: DeleteSupplyDetailsAction): Observable<any> {
    const payload: IDeleteSupplyDetailsPayload = action.payload;
    return this.deleteDataModel(payload.supplyDetailsId).pipe(
      mergeMap((response: DeleteDocumentResponse) => {
        const doc: IElasticSearchDocument<any> = { _id: response._id, ...{} };
        return this.notificationService.notifyRecordDeleted(doc).pipe(
          map((notified: boolean) => ({
            ...response
          }))
        );
      })
    );
  }

  public dispatchDeleteSupplyDetailsSuccess(response: any): Action {
    const successPayload: IDeleteSupplyDetailsSuccessPayload = {};
    const successAction: DeleteSupplyDetailsSuccessAction = new DeleteSupplyDetailsSuccessAction(successPayload);

    return successAction;
  }

  public dispatchDeleteSupplyDetailsFailure(failure: ITryCatchError<DeleteSupplyDetailsAction, any>): Action {
    const failurePayload: IDeleteSupplyDetailsFailurePayload = {};
    const failureAction: DeleteSupplyDetailsFailureAction = new DeleteSupplyDetailsFailureAction(failurePayload);

    return failureAction;
  }

  //#endregion

  //#endregion

  //#region [Features]
  /**
   * Gets bulk action summary
   * @param params
   * @returns bulk action summary
   */
  public getPersistSummary(params: IStartPersistSupplyDetailsPayload): Observable<IEditActionSummary> {
    return this.composePersistSummaryQuery().pipe(
      switchMap((request: IDataRequestParams) => {
        return this.searchDataModel(request).pipe(
          map((response: any) => {
            return this.composePersistActionSummary(response);
          })
        );
      })
    );
  }

  public findSupplyDetailsAction(action: FindSupplyDetailsAction): Observable<any> {

    return this.composeFindSupplyDetailsFilter(action.payload).pipe(
      mergeMap((request: any) => {
        return this.searchDataModel(request);
      }
      )
    );

  }

  public dispatchFindSupplyDetailsSuccess(response: any): Action {

    const resultItemFinded: IElasticSearchDocument<ISupplyDetailsDocument> = firstOrDefault(response.hits.hits);
    const resultItemFindedDoc: ISupplyDetailsDocument | any = resultItemFinded ? resultItemFinded._source : {};
    resultItemFindedDoc.id = resultItemFinded._id;

    const payload: IFindSupplyDetailsSuccessPayLoad = {
      supplyDetailsResult: {
        finded: !isEmpty(resultItemFindedDoc),
        resultItem: resultItemFindedDoc
      }
    };

    return new FindSupplyDetailsSuccessAction(payload);

  }

  public dispatchFindSupplyDetailsFailure(failure: any): any {
    const action = new FindSupplyDetailsFailureAction({});
    return action;
  }



  private composeFindSupplyDetailsFilter(findSupplyDetails: IFindSupplyDetailsPayLoad): any {


    const filter: Array<IQueryFilterModel> = [{
      supplyParamsProductId: {
        filterType: 'text',
        type: enDataModelFilterTypes.equals,
        filter: findSupplyDetails.productId
      }
    }];

    filter.push(
      this._composeStoreQuery(findSupplyDetails)
    );

    filter.push(
      this._composeDistributorQuery(findSupplyDetails)
    );

    return this.applyFilterInQuery(
      {
        size: 1
      },
      ...filter);

  }
  //#endregion

  //#region [Private Methods]

  private composePersistSummaryQuery(): Observable<IDataRequestParams> {
    const query: IDataRequestParams = {
      _source: false,
      size: 0,
      esAggs: {
        totalItems: {
          value_count: {
            field: '_id'
          }
        },
        totalDistributors: {
          cardinality: {
            field: 'distribution_node_source_id'
          }
        },
        totalProducts: {
          cardinality: {
            field: 'product_id'
          }
        },
        totalStores: {
          cardinality: {
            field: 'distribution_node_target_id'
          }
        }
      }
    };

    return this.applyFilterInQuery(
      query
    );
  }

  private composePersistActionSummary(
    response: IAggregationResponse<IEditActionSummaryResponse>
  ): IEditActionSummary {

    const aggs: IEditActionSummaryResponse = response.aggregations;

    return {
      totalItems: aggs.totalItems.value,
      totalProducts: aggs.totalProducts.value,
      totalStores: aggs.totalStores.value,
      totalDistributors: aggs.totalDistributors.value
    };
  }

  /**
   * Composes store query
   * @param findSupplyDetails
   * @returns store query
   */
  private _composeStoreQuery(findSupplyDetails: IFindSupplyDetailsPayLoad): IQueryFilterModel {

    if (findSupplyDetails.storeId) {

       return {
          supplyParamsDistributionNodeTargetId: {
            filterType: 'text',
            type: enDataModelFilterTypes.equals,
            filter: findSupplyDetails.storeId
          }
        };
    } else {
        return {
          supplyParamsDistributionNodeTargetId: {
            type: enDataModelFilterTypes.isNotNull
          }
        };
    }
  }

  /**
   * Composes distributor query
   * @param findSupplyDetails
   * @returns distributor query
   */
  private _composeDistributorQuery(findSupplyDetails: IFindSupplyDetailsPayLoad): IQueryFilterModel {

    if (findSupplyDetails.distributionId) {
      return {
          supplyParamsDistributionNodeSourceId: {
            filterType: 'text',
            type: enDataModelFilterTypes.equals,
            filter: findSupplyDetails.distributionId
          }
        };
    } else {
      return {
        supplyParamsDistributionNodeSourceId: {
          type: enDataModelFilterTypes.isNotNull
        }
      };
    }
  }
  //#endregion
}
