import {
  IEsFieldAggregationResult,
  IElasticSearchModel,
  IFieldMap,
  INestedDistributionNodeDocument,
  INestedProductBaseDocument,
  IDistributionNode,
  IProductBase,
  DistributionNodeBaseFieldMapping,
  ProductBaseFieldMapping,
  enDataState,
  TransactionFieldMapping,
  ITransactionEntity,
  IElasticSearchDocument,
  IFilterModel,
  IBucketAggregationResult,
  IBucketAggregationResultItem,
  INestedDistributionNodeBaseFields,
  INestedProductBaseFields,
} from 'ui-components';

//#region [Enumerators]

/**
 * Enum for bulk reprove modal buttons
 */
export enum enBulkDeleteSupplyDetailsModalButtons {
  yesButton = 'yesButton',
  noButton = 'noButton'
}

/**
 * Enum for persist modal buttons
 */
export enum enPersistModalButtons {
  closeButton = 'closeButton',
  saveButton = 'saveButton',
  saveAndCloseButton = 'saveAndCloseButton'
}

//#endregion

//#region [Interfaces]

/**
 * Ifind supply details result
 */
export interface IFindSupplyDetailsResult {
  finded: boolean;
  resultItem: ISupplyDetailsDocument;
  isLoading?: boolean;
  onError?: boolean;
}

// export interface IEditableSupplyParamsFields

//#endregion

//#region [Data summary]

/**
 * Summarized information for supply details' footer
 */
export interface ISupplyDetailsSummary {
  totalItemsCount: number;
}

/**
 * Summarized information for supply details' footer
 */
export interface ISupplyDetailsSummaryResponse {
  totalItemsCount: IEsFieldAggregationResult;
}

/**
 * Selected data information
 */
export interface ISelectionSummary {
  selectedItemsCount: number;
  isLoading?: boolean;
  onError?: boolean;
}

/**
 * Selected data information
 */
export interface ISelectionSummaryResponse {
  selectedItemsCount: IEsFieldAggregationResult;
  isLoading?: IEsFieldAggregationResult;
  onError?: IEsFieldAggregationResult;
}

/**
 * Iedit action summary
 */
export interface IEditActionSummary {
  totalItems: number;
  totalProducts: number;
  totalStores: number;
  totalDistributors: number;
}

/**
 * Bulk action summary aggregation response interface
 */
export interface IEditActionSummaryResponse {

  totalItems: IEsFieldAggregationResult;
  totalProducts: IEsFieldAggregationResult;
  totalStores: IEsFieldAggregationResult;
  totalDistributors: IEsFieldAggregationResult;

}
export interface IBulkActionSummary {
  selectionFilter: { [key: string]: IFilterModel };
  selectedItemsCount: number;
  selectedDocumentIds: string[];
}

export interface IPersistSupplyDetailsResult {
  persisted: boolean;
}

export interface IBulkActionSummaryResponse {
  selectedItemsCount: IEsFieldAggregationResult;
  selectedDocumentIds: IBucketAggregationResult<IBucketAggregationResultItem>;
}

//#endregion

//#region [ElasticSearch interactions]

/**
 * ES response format for each single Order document
 */
export interface ISupplyDetailsDocument {
  id: any;
  supply_params_horizon_planning: number;
  supply_params_leadtime: number;

  product_maximum_inventory: number;
  product_minimum_inventory: number;
  product_multiple: number;
  product_purchase_cost: number;
  product_sale_price: number;
  product_service_level: string;
  product_shelflife: string;

  supply_params_data_state: enDataState;
  supply_params_locked_user: string;
  supply_params_updated_by: string;
  supply_params_updated_in: string;
  supply_params_created_by: string;
  supply_params_created_in: string;

  distribution_node_source: INestedDistributionNodeDocument;
  distribution_node_target: INestedDistributionNodeDocument;
  product: INestedProductBaseDocument;

  distribution_node_source_id: string;
  distribution_node_target_id: string;
  product_id: string;

}

export interface ISupplyDetails extends ITransactionEntity, IElasticSearchModel<ISupplyDetailsDocument> {
  id: string;
  horizonPlanning: number;
  leadtime: number;

  maximumInventory: number;
  minimumInventory: number;
  multiple: number;
  purchaseCost: number;
  salePrice: number;
  serviceLevel: string;
  shelflife: string;

  supplyParamsproductId?: string;
  supplyParamsDistributionNodeSourceId?: string;
  supplyParamsDistributionNodeTargetId?: string;

  distributionNodeSource?: INestedDistributionNodeBaseFields;
  distributionNodeTarget?: INestedDistributionNodeBaseFields;
  product?: INestedProductBaseFields;
}

//#endregion

//#region [Mappings]

/**
 * Products field mapping
 */
export class SupplyDetailsFieldMapping extends TransactionFieldMapping {

  //#region [Fields]

  /**
   * ID of supply details field mapping
   */
  private id: IFieldMap = {
    name: 'id',
    field: '_id',
    asKeyword: false,
    onQuery: false,
  };

  /**
   * Supply params horizon planning of supply details field mapping
   */
  private supplyParamsHorizonPlanning: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('horizon_planning'),
    field: this.getFieldWithPrefix('horizon_planning'),
    asKeyword: false,
    onQuery: true
  };

  /**
   * Supply params leadtime of supply details field mapping
   */
  private supplyParamsLeadtime: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('leadtime'),
    field: this.getFieldWithPrefix('leadtime'),
    asKeyword: false,
    onQuery: true
  };

  /**
   * Supply params maximum inventory of supply details field mapping
   */
  private supplyParamsMaximumInventory: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('maximum_inventory'),
    field: 'product_maximum_inventory', // this.getFieldWithPrefix('maximum_inventory'),
    asKeyword: false,
    onQuery: true
  };

  /**
   * Supply params minimum inventory of supply details field mapping
   */
  private supplyParamsMinimumInventory: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('minimum_inventory'),
    field: 'product_minimum_inventory', // this.getFieldWithPrefix('minimum_inventory'),
    asKeyword: false,
    onQuery: true
  };

  /**
   * Supply params multiple of supply details field mapping
   */
  private supplyParamsMultiple: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('multiple'),
    field: 'product_multiple', // this.getFieldWithPrefix('multiple'),
    asKeyword: false,
    onQuery: true
  };

  /**
   * Supply params purchase cost of supply details field mapping
   */
  private supplyParamsPurchaseCost: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('purchase_cost'),
    field: 'product_purchase_cost', // this.getFieldWithPrefix('purchase_cost'),
    asKeyword: false,
    onQuery: true
  };

  /**
   * Supply params sale price of supply details field mapping
   */
  private supplyParamsSalePrice: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('sale_price'),
    field: 'product_sale_price', // this.getFieldWithPrefix('sale_price'),
    asKeyword: false,
    onQuery: true
  };

  /**
   * Supply params service level of supply details field mapping
   */
  private supplyParamsServiceLevel: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('service_level'),
    field: 'product_service_level', // this.getFieldWithPrefix('service_level'),
    asKeyword: false,
    onQuery: true
  };

  /**
   * Supply params shelflife of supply details field mapping
   */
  private supplyParamsShelflife: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('shelflife'),
    field: 'product_shelflife', // this.getFieldWithPrefix('shelflife'),
    asKeyword: false,
    onQuery: true
  };

  /**
   * Temporary ID mapping to maintain compatibility
   */
  private supplyParamsDistributionNodeSourceId: IFieldMap = {
    name: 'supplyParamsDistributionNodeSourceId',
    field: 'distribution_node_source_id',
    asKeyword: false,
    onQuery: true
  };

  /**
   * Temporary ID mapping to maintain compatibility
   */
  private supplyParamsDistributionNodeTargetId: IFieldMap = {
    name: 'supplyParamsDistributionNodeTargetId',
    field: 'distribution_node_target_id',
    asKeyword: false,
    onQuery: true
  };

  /**
   * Temporary ID mapping to maintain compatibility
   */
  private supplyParamsProductId: IFieldMap = {
    name: 'supplyParamsProductId',
    field: 'product_id',
    asKeyword: false,
    onQuery: true
  };

  /**
   * Distribution node source of supply details field mapping
   */
  private distributionNodeSource: DistributionNodeBaseFieldMapping;

  /**
   * Distribution node target of supply details field mapping
   */
  private distributionNodeTarget: DistributionNodeBaseFieldMapping;

  /**
   * Product of supply details field mapping
   */
  private product: ProductBaseFieldMapping;

  //#endregion

  //#region [Public Methods]

  constructor() {
    super('supply_params');
    this.distributionNodeSource = new DistributionNodeBaseFieldMapping('', 'distribution_node_source');
    this.distributionNodeTarget = new DistributionNodeBaseFieldMapping('', 'distribution_node_target');
    this.product = new ProductBaseFieldMapping('', 'product');
  }

  public getFields(): IFieldMap[] {
    return [
      this.id,
      this.supplyParamsHorizonPlanning,
      this.supplyParamsLeadtime,
      this.supplyParamsMaximumInventory,
      this.supplyParamsMinimumInventory,
      this.supplyParamsMultiple,
      this.supplyParamsPurchaseCost,
      this.supplyParamsSalePrice,
      this.supplyParamsServiceLevel,
      this.supplyParamsShelflife,
      this.supplyParamsDistributionNodeSourceId,
      this.supplyParamsDistributionNodeTargetId,
      this.supplyParamsProductId,
      ...super.getFields(),
      ...this.distributionNodeSource.getFields(),
      ...this.distributionNodeTarget.getFields(),
      ...this.product.getFields(),
    ];
  }

  //#endregion

}

//#endregion
