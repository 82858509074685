import { newState } from 'ui-components';
import { productsInitialState } from './vw-md-products.state';
import { vwMdProductsActions, enProductsActionTypes } from './vw-md-products.actions';

export function productsReducer(state = productsInitialState, action: vwMdProductsActions) {
    switch (action.type) {

        case enProductsActionTypes.PRODUCT_LOAD_DATA_ACTION: {
            return newState(state, {
                dataGridQueryParams: action.payload.request
            });
        }
        case enProductsActionTypes.PRODUCT_LOAD_DATA_SUCCESS_ACTION: {
            return newState(state, {
                summary: action.payload.summary
            });
        }
        case enProductsActionTypes.PRODUCT_LOAD_DATA_FAILURE_ACTION: {
            return newState(state, {
                summary: {
                    ...productsInitialState.summary,
                }
            });
        }

        // -------------------------------------------------------------------------- //

        case enProductsActionTypes.PRODUCT_UPDATE_PRODUCT_ACTION: {
            return state;
        }
        case enProductsActionTypes.PRODUCT_UPDATE_PRODUCT_SUCESS_ACTION: {
            return state;
        }
        case enProductsActionTypes.PRODUCT_UPDATE_PRODUCT_FAILURE_ACTION: {
            return state;
        }

        default: {
            return state;
        }
    }
}
