import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';

import { IDataRequestParams } from 'ui-components';

import { IDistributionNodesState } from './vw-md-distribution-nodes.state';
import { IDistributionNodesSummary } from '../vw-md-distribution-nodes.model';

export const getDistributionNodesState: MemoizedSelector<object, IDistributionNodesState> =
    createFeatureSelector<IDistributionNodesState>('distributionNodes');

const getDataGridQueryParams = (state: IDistributionNodesState): IDataRequestParams => state.dataGridQueryParams;
const getSummary = (state: IDistributionNodesState) => state.summary;

export const getDistributionNodesDataGridQueryParams:
    MemoizedSelector<IDistributionNodesState, IDataRequestParams> = createSelector(getDistributionNodesState, getDataGridQueryParams);

export const getDistributionNodesSummary:
    MemoizedSelector<IDistributionNodesState, IDistributionNodesSummary> = createSelector(getDistributionNodesState, getSummary);
