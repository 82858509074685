import {
  IFieldMap,
  IElasticSearchDocument,
  INestedDistributionNodeBaseDocument,
  IElasticSearchModel,
  INestedDistributionNodeBaseFields,
  INestedProductBaseFields,
  TransactionFieldMapping,
  BaseFieldMaps,
  ProductBaseFieldMapping,
  INestedProductDocument,
  INestedProductFields,
  ProductFieldMapping,
  INestedDistributionNodeDocument,
  INestedDistributionNodeFields,
  DistributionNodeFieldMapping,
  NestableFieldMaps,
  IEsFieldAggregationResult,
  IFilterModel,
  IBucketAggregationResult,
  IBucketAggregationResultItem,
  ITransactionEntity
} from 'ui-components';

export enum enBulkDeleteCampaignsModalButtons {
  yesButton = 'yesButton',
  noButton = 'noButton'
}

//#region [Data summary]

/**
 * Summarized information for campaign's footer
 */
export interface ICampaignsSummary {
  totalItemsCount: number;
  totalBudget: number;
}

/**
 * Summarized information for campaigns' footer
 */
export interface ICampaignsSummaryResponse {
  totalItemsCount: IEsFieldAggregationResult;
  totalBudget: IEsFieldAggregationResult;
}

/**
 * Selected data information
 */
export interface ISelectionSummary {
  selectedItemsCount: number;
  isLoading?: boolean;
  onError?: boolean;
}

/**
 * Selected data information
 */
export interface ISelectionSummaryResponse {
  selectedItemsCount: IEsFieldAggregationResult;
  isLoading?: IEsFieldAggregationResult;
  onError?: IEsFieldAggregationResult;
}

export interface IBulkActionSummary {
  selectionFilter: { [ key: string]: IFilterModel };
  selectedItemsCount: number;
  selecterDocumentIds: string[];
}

export interface IBulkActionSummaryResponse {
  selectedItemsCount: IEsFieldAggregationResult;
  selectedDocumentIds: IBucketAggregationResult<IBucketAggregationResultItem>;
}

//#endregion

//#region [ElasticSearch interactions]

/**
 * ES response format for each single Order document
 */
export interface ICampaignDocument {
  id: string;
  campaign_name: string;
  campaign_classification: string;
  campaign_start_date: string;
  campaign_end_date: string;
  campaign_item_budget: number;
  campaign_item_start_date: string;
  campaign_item_end_date: string;
  product: INestedProductDocument;
  distribution_node: INestedDistributionNodeDocument;
}

export interface ICampaign extends ITransactionEntity, IElasticSearchModel<ICampaignDocument> {
  id: string;
  name: string;
  classification: string;
  startDate: string;
  endDate: string;
  itemBudget: number;
  itemStartDate: string;
  itemEndDate: string;
  product: INestedProductFields;
  distributionNode: INestedDistributionNodeFields;
}

//#endregion

//#region [Mappings]

export class CampaignFieldMapping extends TransactionFieldMapping {

  //#region [Fields]

  private id: IFieldMap = {
    name: 'id',
    field: '_id',
    asKeyword: false,
    onQuery: false,
  };
  private campaignName: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('name'),
    field: this.getFieldWithPrefix('name'),
    asKeyword: true,
    onQuery: true
  };
  private campaignClassification: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('classification'),
    field: this.getFieldWithPrefix('classification'),
    asKeyword: true,
    onQuery: true
  };
  private campaignStartDate: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('start_date'),
    field: this.getFieldWithPrefix('start_date'),
    asKeyword: false,
    onQuery: true
  };
  private campaignEndDate: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('end_date'),
    field: this.getFieldWithPrefix('end_date'),
    asKeyword: false,
    onQuery: true
  };
  private campaignItemBudget: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('item_budget'),
    field: this.getFieldWithPrefix('item_budget'),
    asKeyword: false,
    onQuery: true
  };
  private campaignItemStartDate: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('item_start_date'),
    field: this.getFieldWithPrefix('item_start_date'),
    asKeyword: false,
    onQuery: true
  };
  private campaignItemEndDate: IFieldMap = {
    name: this.getFieldNameWithoutPrefix('item_end_date'),
    field: this.getFieldWithPrefix('item_end_date'),
    asKeyword: false,
    onQuery: true
  };

  private distributionNode: DistributionNodeFieldMapping;

  private product: ProductFieldMapping;

  //#endregion

  //#region [Public Methods]

  constructor() {
    super('campaign');
    this.distributionNode = new DistributionNodeFieldMapping('', 'distribution_node');
    this.product = new ProductFieldMapping('', 'product');
  }

  public getFields(): IFieldMap[] {
    return [
      this.id,
      this.campaignName,
      this.campaignClassification,
      this.campaignStartDate,
      this.campaignEndDate,
      this.campaignItemBudget,
      this.campaignItemStartDate,
      this.campaignItemEndDate,
      ...super.getFields(),
      ...this.distributionNode.getFields(),
      ...this.product.getFields(),
    ];
  }

  //#endregion

}

//#endregion
