import {
  Injectable,
} from '@angular/core';
import {
  Actions,
  Effect,
  ofType,
} from '@ngrx/effects';

import {
  tryCatch,
} from 'ui-components';
import {
  enSupplyDetailsActionTypes,
} from './vw-md-supply-details.actions';
import {
  VwMdSupplyDetailsService,
} from '../vw-md-supply-details.service';

@Injectable()
export class VwMdSupplyDetailsEffects {
  constructor(
    private actions$: Actions,
    private service: VwMdSupplyDetailsService,
  ) { }

  @Effect()
  public loadData$: any = this.actions$.pipe(
    ofType(enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_DATA_ACTION),
    tryCatch({
      action: (a: any) => this.service.loadDataAction(a),
      success: (r: any) => this.service.dispatchLoadDataSuccess(r),
      failed: (e: any) => this.service.dispatchLoadDataFailure(e)
    }),
  );

  @Effect()
  public loadSelectionSummary$: any = this.actions$.pipe(
    ofType(enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_SELECTION_SUMMARY_ACTION),
    tryCatch({
      action: (a: any) => this.service.loadSelectionSummaryAction(a),
      success: (r: any) => this.service.dispatchLoadSelectionSummarySuccess(r),
      failed: (e: any) => this.service.dispatchLoadSelectionSummaryFailure(e)
    }),
  );

  @Effect()
  public startBulkPersist$: any = this.actions$.pipe(
    ofType(enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_PERSIST_SUPPLY_DETAILS_ACTION),
    tryCatch({
      action: (a: any) => this.service.startPersistSupplyDetailsAction(a),
      success: (r: any) => this.service.dispatchStartPersistSupplyDetailsSuccess(r),
      failed: (e: any) => this.service.dispatchStartPersistSupplyDetailsFailure(e)
    }),
  );

  @Effect()
  public persistSupplyDetails$: any = this.actions$.pipe(
    ofType(enSupplyDetailsActionTypes.SUPPLY_DETAILS_PERSIST_SUPPLY_DETAILS_ACTION),
    tryCatch({
      action: (a: any) => this.service.persistSupplyDetails(a),
      success: (r: any) => this.service.dispatchPersistSuccess(r),
      failed: (e: any) => this.service.dispatchPersistFailure(e)
    }),
  );

  @Effect()
  public findSupplyDetail$: any = this.actions$.pipe(
    ofType(enSupplyDetailsActionTypes.SUPPLY_DETAILS_FIND_SUPPLY_DETAILS_ACTION),
    tryCatch({
      action: (a: any) => this.service.findSupplyDetailsAction(a),
      success: (r: any) => this.service.dispatchFindSupplyDetailsSuccess(r),
      failed: (e: any) => this.service.dispatchFindSupplyDetailsFailure(e)
    }),
  );

  @Effect()
  public updateSupplyDetails$: any = this.actions$.pipe(
    ofType(enSupplyDetailsActionTypes.SUPPLY_DETAILS_UPDATE_SUPPLY_DETAILS_ACTION),
    tryCatch({
      action: (a: any) => this.service.updateSupplyDetailsAction(a),
      success: (r: any) => this.service.dispatchUpdateSupplyDetailsSuccess(r),
      failed: (e: any) => this.service.dispatchUpdateSupplyDetailsFailure(e)
    }),
  );

  @Effect()
  public startBulkDeleteSupplyDetails$: any = this.actions$.pipe(
    ofType(enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_BULK_DELETE_ACTION),
    tryCatch({
      action: (a: any) => this.service.startBulkDeleteSupplyDetailsAction(a),
      success: (r: any) => this.service.dispatchStartBulkDeleteSupplyDetailsSuccess(r),
      failed: (e: any) => this.service.dispatchStartBulkDeleteSupplyDetailsFailure(e)
    }),
  );

  @Effect()
  public bulkDeleteSupplyDetails$: any = this.actions$.pipe(
    ofType(enSupplyDetailsActionTypes.SUPPLY_DETAILS_BULK_DELETE_ACTION),
    tryCatch({
      action: (a: any) => this.service.bulkDeleteSupplyDetailsAction(a),
      success: (r: any) => this.service.dispatchBulkDeleteSupplyDetailsSuccess(r),
      failed: (e: any) => this.service.dispatchBulkDeleteSupplyDetailsFailure(e)
    }),
  );

  @Effect()
  public deleteSupplyDetails$: any = this.actions$.pipe(
    ofType(enSupplyDetailsActionTypes.SUPPLY_DETAILS_DELETE_ITEM_ACTION),
    tryCatch({
      action: (a: any) => this.service.deleteSupplyDetailsAction(a),
      success: (r: any) => this.service.dispatchDeleteSupplyDetailsSuccess(r),
      failed: (e: any) => this.service.dispatchDeleteSupplyDetailsFailure(e)
    }),
  );
}
