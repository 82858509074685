import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { SearchResponse } from 'elasticsearch';
import {
    IDataRequestParams,
    TvcDataModelService,
    IQueryFilterModel,
    switchResponse,
    ISwitchResponse,
    ITryCatchError,
} from 'ui-components';
import {
    LoadDataAction,
    ILoadDataSuccessPayload,
    LoadDataSuccessAction,
    ILoadDataFailurePayload,
    LoadDataFailureAction
} from './store/vw-md-promotions.actions';
import { IPromotionsState } from './store/vw-md-promotions.state';
import { IPromotionDocument, PromotionFieldMapping, IPromotionsSummary, IPromotionsSummaryResponse } from './vw-md-promotions.model';

@Injectable({
    providedIn: 'root'
})
export class VwMdPromotionsService extends TvcDataModelService {

    //#region [ Local Variables ]

    public readonly indexName = 'idx_promotions';

    public readonly documentType = '_doc';

    public fieldMapping: any = new PromotionFieldMapping();

    //#endregion

    //#region [ Properties ]

    public get summaryAggregation() {
        return {
            totalItemsCount: {
                value_count: {
                    field: '_id'
                }
            },
            totalDiscount: {
                sum: {
                    field: 'promotion_discount'
                }
            },
        };
    }

    //#endregion

    //#region [ Initialization ]

    constructor(
        public store: Store<IPromotionsState>,
    ) {
        super();
        this.initialize();
    }

    //#endregion

    //#region [ Private Methods ]

    private prepareLoadDataQuery(request: IDataRequestParams): Observable<IDataRequestParams> {
        if (!request.sort.length) {
            request.sort.push({ colId: '_id', sort: 'asc' });
        }
        request._source = this.displayedFields;
        request.esAggs = Object.assign(
            request.esAggs || {},
            this.summaryAggregation
        );
        const aggQuery: IQueryFilterModel = request.aggQuery as IQueryFilterModel;
        return this.applyContextFilterInQuery(request, aggQuery);
    }

    private composeSummary(response: SearchResponse<IPromotionDocument>): IPromotionsSummary {
        try {
            const aggs: IPromotionsSummaryResponse = response.aggregations;

            const summary: IPromotionsSummary = {
                totalItemsCount: aggs.totalItemsCount.value,
                totalDiscount: aggs.totalDiscount.value
            };
            return summary;

        } catch (error) {
            return {
                totalItemsCount: 0,
                totalDiscount: 0
            };
        }
    }

    //#endregion

    //#region [ Actions ]

    public loadDataAction(action: LoadDataAction): Observable<any> {
        const parser = (data: any) => this.dmParser.fromEs(data);
        return this.prepareLoadDataQuery(action.payload.request).pipe(
            mergeMap((request: IDataRequestParams) => {
                return this.searchDataModel(request).pipe(
                    switchResponse(request, { parser })
                );
            })
        );
    }

    public dispatchLoadDataSuccess(source: ISwitchResponse<IPromotionDocument>): Action {
        const summary: IPromotionsSummary = this.composeSummary(source.response);
        const payload: ILoadDataSuccessPayload = { summary };
        return new LoadDataSuccessAction(payload);
    }

    public dispatchLoadDataFailure(failure: ITryCatchError<LoadDataAction, any>): Action {
        failure.action.payload.request.failCallback();
        const failurePayload: ILoadDataFailurePayload = {};
        const failureAction: LoadDataFailureAction = new LoadDataFailureAction(failurePayload);
        return failureAction;
    }

    //#endregion


}
