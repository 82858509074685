import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  TvcMasterPageModule,
  TvcLoadingModule,
} from 'ui-components';

import { VwMdMasterPageRoutingModule } from './vw-md-master-page-routing.module';
import { VwMdMasterPageComponent } from './vw-md-master-page.component';


@NgModule({
  declarations: [VwMdMasterPageComponent],
  imports: [
    CommonModule,
    TvcLoadingModule,
    VwMdMasterPageRoutingModule,
    TvcMasterPageModule,
  ],
  exports: [VwMdMasterPageComponent]
})
export class VwMdMasterPageModule { }
