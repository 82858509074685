import {
  newState,
  updateNestedState,
} from 'ui-components';

import {
  vwMdCampaignsActions,
  enCampaignsActionTypes
} from './vw-md-campaigns.actions';
import {
  campaignsInitialState,
} from './vw-md-campaigns.state';

export function campaignsReducer(state = campaignsInitialState, action: vwMdCampaignsActions) {
  switch (action.type) {
    case enCampaignsActionTypes.CAMPAIGNS_LOAD_DATA_ACTION: {
      return newState(state, {
        dataGridQueryParams: action.payload.request
      });
    }
    case enCampaignsActionTypes.CAMPAIGNS_LOAD_DATA_SUCCESS_ACTION: {
      return newState(state, {
        summary: action.payload.summary
      });
    }
    case enCampaignsActionTypes.CAMPAIGNS_LOAD_DATA_FAILURE_ACTION: {
      return state;
    }

    // -------------------------------------------------------------------------- //

    case enCampaignsActionTypes.CAMPAIGNS_LOAD_SELECTION_SUMMARY_ACTION: {
      return updateNestedState(state, 'selectionSummary', {
        isLoading: true,
        onError: false,
      });
    }

    case enCampaignsActionTypes.CAMPAIGNS_LOAD_SELECTION_SUMMARY_SUCCESS_ACTION: {
      return newState(state, {
        selectionSummary: {
          ...action.payload.summary,
          isLoading: false,
          onError: false,
        }
      });
    }

    case enCampaignsActionTypes.CAMPAIGNS_LOAD_SELECTION_SUMMARY_FAILURE_ACTION: {
      return newState(state, {
        selectionSummary: {
          ...campaignsInitialState.selectionSummary,
          isLoading: false,
          onError: true,
        }
      });
    }

    case enCampaignsActionTypes.CAMPAIGNS_RESET_SELECTION_SUMMARY_ACTION: {
      return updateNestedState(state, 'selectionSummary', campaignsInitialState.selectionSummary);
    }

    // -------------------------------------------------------------------------- //

    case enCampaignsActionTypes.CAMPAIGNS_START_BULK_DELETE_ACTION: {
      return updateNestedState(state, 'bulkDeleteModalState', {
        isLoading: true,
        onError: false
      });
    }

    case enCampaignsActionTypes.CAMPAIGNS_START_BULK_DELETE_SUCCESS_ACTION: {
      return newState(
        updateNestedState(state, 'bulkDeleteModalState', {
          isLoading: false
        }),
        {
          bulkActionSummary: action.payload.bulkActionSummary
        }
      );
    }

    case enCampaignsActionTypes.CAMPAIGNS_START_BULK_DELETE_FAILURE_ACTION: {
      return newState(
        updateNestedState(state, 'bulkDeleteModalState', {
          isLoading: false,
          onError: false
        }),
        {
          bulkActionSummary: campaignsInitialState.bulkActionSummary
        }
      );
    }

    // -------------------------------------------------------------------------- //

    default: {
      return state;
    }
  }
}
