import {
  IUserAccessProfile,
  enAccessGroup,
  enAccessRole,
  enAccessPolicy,
} from 'ui-components';

export const distributionNodeAccessProfiles: IUserAccessProfile = {
  groups: [
    enAccessGroup.admin,
    enAccessGroup.administrators,
    enAccessGroup.analysts,
    enAccessGroup.guests,
  ],
  roles: [
    enAccessRole.SeeB4AdministratorsRole,
    enAccessRole.SeeB4AnalystsRole,
  ],
  policies: [
    enAccessPolicy.MasterDataDistributionNodeFullAccess,
    enAccessPolicy.MasterDataDistributionNodeShortViewFullAccess,
    enAccessPolicy.MasterDataDistributionNodeReadOnlyAccess,
    enAccessPolicy.MasterDataDistributionNodeShortViewReadOnlyAccess,
  ],
};

export const productsAccessProfiles: IUserAccessProfile = {
  groups: [
    enAccessGroup.admin,
    enAccessGroup.administrators,
    enAccessGroup.analysts,
    enAccessGroup.guests,
  ],
  roles: [
    enAccessRole.SeeB4AdministratorsRole,
    enAccessRole.SeeB4AnalystsRole,
  ],
  policies: [
    enAccessPolicy.MasterDataProductFullAccess,
    enAccessPolicy.MasterDataProductShortViewFullAccess,
    enAccessPolicy.MasterDataProductReadOnlyAccess,
    enAccessPolicy.MasterDataProductShortViewReadOnlyAccess,
  ],
};

export const supplyDetailsAccessProfiles: IUserAccessProfile = {
  groups: [
    enAccessGroup.admin,
    enAccessGroup.administrators,
    enAccessGroup.analysts,
    enAccessGroup.guests,
  ],
  roles: [
    enAccessRole.SeeB4AdministratorsRole,
    enAccessRole.SeeB4AnalystsRole,
  ],
  policies: [
    enAccessPolicy.MasterDataSupplyDetailsFullAccess,
    enAccessPolicy.MasterDataSupplyDetailsShortViewFullAccess,
    enAccessPolicy.MasterDataSupplyDetailsReadOnlyAccess,
    enAccessPolicy.MasterDataSupplyDetailsShortViewReadOnlyAccess,
  ],
};

export const campaignsAccessProfiles: IUserAccessProfile = {
  groups: [
    enAccessGroup.admin,
    enAccessGroup.administrators,
    enAccessGroup.analysts,
    enAccessGroup.guests,
  ],
  roles: [
    enAccessRole.SeeB4AdministratorsRole,
    enAccessRole.SeeB4AnalystsRole,
  ],
  policies: [
    enAccessPolicy.MasterDataCampaignFullAccess,
    enAccessPolicy.MasterDataCampaignShortViewFullAccess,
    enAccessPolicy.MasterDataCampaignReadOnlyAccess,
    enAccessPolicy.MasterDataCampaignShortViewReadOnlyAccess,
  ],
};
export const promotionsAccessProfiles: IUserAccessProfile = {
  groups: [
    enAccessGroup.admin,
    enAccessGroup.administrators,
    enAccessGroup.analysts,
    enAccessGroup.guests,
  ],
  roles: [
    enAccessRole.SeeB4AdministratorsRole,
    enAccessRole.SeeB4AnalystsRole,
  ],
  policies: [
    enAccessPolicy.MasterDataPromotionFullAccess,
    enAccessPolicy.MasterDataPromotionShortViewFullAccess,
    enAccessPolicy.MasterDataPromotionReadOnlyAccess,
    enAccessPolicy.MasterDataPromotionShortViewReadOnlyAccess,
  ],
};
