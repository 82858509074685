import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  TvcAuthGuardService,
  TvcAuthRoleGuardService,
} from 'ui-components';

import {
  distributionNodeAccessProfiles,
  productsAccessProfiles,
  supplyDetailsAccessProfiles,
  campaignsAccessProfiles,
  promotionsAccessProfiles,
} from './models/auth-guard';

import { VwMdMasterPageComponent } from './vw-md-master-page/vw-md-master-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'master-data/distribution-nodes',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./vw-md-login-page/vw-md-login-page.module').then(m => m.VwPoLoginPageModule)
  },
  {
    path: 'master-data',
    component: VwMdMasterPageComponent,
    children: [
      {
        path: '',
        canActivate: [
          TvcAuthGuardService,
          TvcAuthRoleGuardService,
        ],
        redirectTo: 'master-data/master-data',
        pathMatch: 'full'
      },
      {
        path: 'distribution-nodes',
        canActivate: [
          TvcAuthGuardService,
          TvcAuthRoleGuardService,
        ],
        data: {
          accessProfile: distributionNodeAccessProfiles,
        },
        loadChildren: () => import('src/app/vw-md-distribution-nodes/vw-md-distribution-nodes.module')
          .then(m => m.VwMdDistributionNodesModule)
      },
      {
        path: 'products',
        canActivate: [
          TvcAuthGuardService,
          TvcAuthRoleGuardService,
        ],
        data: {
          accessProfile: productsAccessProfiles,
        },
        loadChildren: () => import('src/app/vw-md-products/vw-md-products.module')
          .then(m => m.VwMdProductsModule)
      },
      {
        path: 'supply-details',
        canActivate: [
          TvcAuthGuardService,
          TvcAuthRoleGuardService,
        ],
        data: {
          accessProfile: supplyDetailsAccessProfiles,
        },
        loadChildren: () => import('src/app/vw-md-supply-details/vw-md-supply-details.module')
          .then(m => m.VwMdSupplyDetailsModule)
      },
      // {
      //   path: 'campaigns',
      //   canActivate: [
      //     TvcAuthGuardService,
      //     TvcAuthRoleGuardService,
      //   ],
      //   data: {
      //     accessProfile: campaignsAccessProfiles,
      //   },
      //   loadChildren: () => import('src/app/vw-md-campaigns/vw-md-campaigns.module')
      //     .then(m => m.VwMdCampaignsModule)
      // },
      // {
      //   path: 'promotions',
      //   canActivate: [
      //     TvcAuthGuardService,
      //     TvcAuthRoleGuardService,
      //   ],
      //   data: {
      //     accessProfile: promotionsAccessProfiles,
      //   },
      //   loadChildren: () => import('src/app/vw-md-promotions/vw-md-promotions.module')
      //     .then(m => m.VwMdPromotionsModule)
      // },
    ]
  }

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
