import {
  newState,
  updateNestedState,
} from 'ui-components';

import {
  vwMdSupplyDetailsActions,
  enSupplyDetailsActionTypes,
} from './vw-md-supply-details.actions';
import {
  supplyDetailsInitialState,
} from './vw-md-supply-details.state';

export function supplyDetailsReducer(state = supplyDetailsInitialState, action: vwMdSupplyDetailsActions) {
  switch (action.type) {
    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_DATA_ACTION: {
      return newState(state, {
        dataGridQueryParams: action.payload.request
      });
    }
    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_DATA_SUCCESS_ACTION: {
      return newState(state, {
        summary: action.payload.summary
      });
    }
    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_DATA_FAILURE_ACTION: {
      return newState(state, {
        summary: supplyDetailsInitialState.summary
      });
    }

    // -------------------------------------------------------------------------- //

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_SELECTION_SUMMARY_ACTION: {
      return updateNestedState(state, 'selectionSummary', {
        isLoading: true,
        onError: false,
      });
    }

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_SELECTION_SUMMARY_SUCCESS_ACTION: {
      return newState(state, {
        selectionSummary: {
          ...action.payload.summary,
          isLoading: false,
          onError: false,
        }
      });
    }

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_LOAD_SELECTION_SUMMARY_FAILURE_ACTION: {
      return newState(state, {
        selectionSummary: {
          ...supplyDetailsInitialState.selectionSummary,
          isLoading: false,
          onError: true,
        }
      });
    }

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_RESET_SELECTION_SUMMARY_ACTION: {
      return updateNestedState(state, 'selectionSummary', supplyDetailsInitialState.selectionSummary);
    }

    // ------------------------------------------------------------------------------------------ //

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_PERSIST_SUPPLY_DETAILS_ACTION: {
      return updateNestedState(state, 'persistModalState', {
        isLoading: true,
        onError: false
      });
    }
    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_PERSIST_SUPPLY_DETAILS_SUCCESS_ACTION: {
      return newState(
        updateNestedState(state, 'persistModalState', {
          isLoading: false
        }),
        {
          persistSummary: action.payload.actionSummary
        }
      );
    }
    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_PERSIST_SUPPLY_DETAILS_FAILURE_ACTION: {
      return updateNestedState(state, 'persistModalState', {
        isLoading: false,
        onError: true
      });
    }

    // -------------------------------------------------------------------------- //

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_PERSIST_SUPPLY_DETAILS_ACTION: {
      return state;
    }

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_PERSIST_SUPPLY_DETAILS_SUCCESS_ACTION: {
      return newState(
        updateNestedState(state, 'persistSupplyDetailsResult', {
        persisted: true,
      }),
      {
        findSupplyDetailsResult: supplyDetailsInitialState.findSupplyDetailsResult
      });
    }

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_PERSIST_SUPPLY_DETAILS_FAILURE_ACTION: {
      return newState(
        updateNestedState(state, 'persistSupplyDetailsResult', {
        persisted: false,
      }),
      {
        findSupplyDetailsResult: supplyDetailsInitialState.findSupplyDetailsResult
      });
    }
    // -------------------------------------------------------------------------- //


    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_FIND_SUPPLY_DETAILS_ACTION: {
      return newState(
        updateNestedState(state, 'findSupplyDetailsResult', {
          isLoading: true,
          onError: false
        })
      );
    }

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_FIND_SUPPLY_DETAILS_SUCCESS_ACTION: {
      return newState(
        state,
        {
          findSupplyDetailsResult: {
            ...action.payload.supplyDetailsResult,
            isLoading: false,
            onError: false
          }
        }
      );
    }
    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_FIND_SUPPLY_DETAILS_FAILURE_ACTION: {
      return newState(
        state,
        {
          findSupplyDetailsResult: {
            ...supplyDetailsInitialState.findSupplyDetailsResult,
            isLoading: false,
            onError: true
          }
        }
      );
    }

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_RESET_SUPPLY_DETAILS_RESULT_ACTION: {
      return updateNestedState(state,
        'findSupplyDetailsResult',
        supplyDetailsInitialState.findSupplyDetailsResult
      );

    }
    // -------------------------------------------------------------------------- //

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_BULK_DELETE_ACTION: {
      return updateNestedState(state, 'bulkDeleteModalState', {
        isLoading: true,
        onError: false
      });
    }

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_BULK_DELETE_SUCCESS_ACTION: {
      return newState(
        updateNestedState(state, 'bulkDeleteModalState', {
          isLoading: false
        }),
        {
          bulkActionSummary: action.payload.bulkActionSummary
        }
      );
    }

    case enSupplyDetailsActionTypes.SUPPLY_DETAILS_START_BULK_DELETE_FAILURE_ACTION: {
      return newState(
        updateNestedState(state, 'bulkDeleteModalState', {
          isLoading: false,
          onError: false
        }),
        {
          bulkActionSummary: supplyDetailsInitialState.bulkActionSummary
        }
      );
    }

    // -------------------------------------------------------------------------- //

    default: {
      return state;
    }
  }
}
