import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { tryCatch } from 'ui-components';
import { enPromotionsActionTypes } from './vw-md-promotions.actions';
import { VwMdPromotionsService } from '../vw-md-promotions.service';

@Injectable()
export class VwMdPromotionsEffects {
    constructor(
        private actions$: Actions,
        private service: VwMdPromotionsService
    ) { }

    @Effect()
    public loadData$: any = this.actions$.pipe(
        ofType(enPromotionsActionTypes.PROMOTION_LOAD_DATA_ACTION),
        tryCatch({
            action: (a: any) => this.service.loadDataAction(a),
            success: (r: any) => this.service.dispatchLoadDataSuccess(r),
            failed: (e: any) => this.service.dispatchLoadDataFailure(e)
        })
    );

}
