import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';

import { tryCatch } from 'ui-components';

import { enProductsActionTypes } from './vw-md-products.actions';
import { VwMdProductsService } from '../vw-md-products.service';

@Injectable()
export class VwMdProductsEffects {
    constructor(
        private actions$: Actions,
        private service: VwMdProductsService
    ) { }

    @Effect()
    public loadData$: any = this.actions$.pipe(
        ofType(enProductsActionTypes.PRODUCT_LOAD_DATA_ACTION),
        tryCatch({
            action: (a: any) => this.service.loadDataAction(a),
            success: (r: any) => this.service.dispatchLoadDataSuccess(r),
            failed: (e: any) => this.service.dispatchLoadDataFailure(e)
        })
    );

    // -------------------------------------------------------------------------- //

    @Effect()
    public updateProduct$: any = this.actions$.pipe(
        ofType(enProductsActionTypes.PRODUCT_UPDATE_PRODUCT_ACTION),
        tryCatch({
            action: (a: any) => this.service.updateProductAction(a),
            success: (r: any) => this.service.dispatchUpdateProductSuccess(r),
            failed: (e: any) => this.service.dispatchUpdateProductFailure(e)
        })
    );
}
