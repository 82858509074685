import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';

import { IDataRequestParams } from 'ui-components';

import { IProductsState } from './vw-md-products.state';
import { IProductsSummary } from '../vw-md-products.model';

export const getProductsState: MemoizedSelector<object, IProductsState> =
    createFeatureSelector<IProductsState>('products');

const getDataGridQueryParams = (state: IProductsState): IDataRequestParams => state.dataGridQueryParams;
const getSummary = (state: IProductsState) => state.summary;

export const getProductsDataGridQueryParams:
    MemoizedSelector<IProductsState, IDataRequestParams> = createSelector(getProductsState, getDataGridQueryParams);

export const getProductsSummary:
    MemoizedSelector<IProductsState, IProductsSummary> = createSelector(getProductsState, getSummary);
