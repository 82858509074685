import { Action } from '@ngrx/store';
import { IDataRequestParams, IQueryFilterModel } from 'ui-components';
import { ICampaignsSummary, ISelectionSummary, ICampaign, IBulkActionSummary } from '../vw-md-campaigns.model';

export enum enCampaignsActionTypes {

  CAMPAIGNS_LOAD_DATA_ACTION = '[vw-md-campaigns] Load Data Action',
  CAMPAIGNS_LOAD_DATA_SUCCESS_ACTION = '[vw-md-campaigns] Load Data Success Action',
  CAMPAIGNS_LOAD_DATA_FAILURE_ACTION = '[vw-md-campaigns] Load Data Failure Action',

  CAMPAIGNS_LOAD_SELECTION_SUMMARY_ACTION = '[vw-md-campaigns] Load Selection Summary Action',
  CAMPAIGNS_LOAD_SELECTION_SUMMARY_SUCCESS_ACTION = '[vw-md-campaigns] Load Selection Summary Success Action',
  CAMPAIGNS_LOAD_SELECTION_SUMMARY_FAILURE_ACTION = '[vw-md-campaigns] Load Selection Summary Failure Action',
  CAMPAIGNS_RESET_SELECTION_SUMMARY_ACTION = '[vw-md-campaigns] Reset Selection Summary Action',

  CAMPAIGNS_CREATE_ITEM_ACTION = '[vw-md-campaigns] Create Item Action',
  CAMPAIGNS_CREATE_ITEM_SUCCESS_ACTION = '[vw-md-campaigns] Create Item Success Action',
  CAMPAIGNS_CREATE_ITEM_FAILURE_ACTION = '[vw-md-campaigns] Create Item Failure Action',

  CAMPAIGNS_EDIT_ITEM_ACTION = '[vw-md-campaigns] Edit Item Action',
  CAMPAIGNS_EDIT_ITEM_SUCCESS_ACTION = '[vw-md-campaigns] Edit Item Success Action',
  CAMPAIGNS_EDIT_ITEM_FAILURE_ACTION = '[vw-md-campaigns] Edit Item Failure Action',

  CAMPAIGNS_START_BULK_DELETE_ACTION = '[vw-md-campaigns] Start Bulk Delete Action',
  CAMPAIGNS_START_BULK_DELETE_SUCCESS_ACTION = '[vw-md-campaigns] Start Bulk Delete Success Action',
  CAMPAIGNS_START_BULK_DELETE_FAILURE_ACTION = '[vw-md-campaigns] Start Bulk Delete Failure Action',

  CAMPAIGNS_BULK_DELETE_ACTION = '[vw-md-campaigns] Bulk Delete Action',
  CAMPAIGNS_BULK_DELETE_SUCCESS_ACTION = '[vw-md-campaigns] Bulk Delete Success Action',
  CAMPAIGNS_BULK_DELETE_FAILURE_ACTION = '[vw-md-campaigns] Bulk Delete Failure Action',

  CAMPAIGNS_DELETE_ITEM_ACTION = '[vw-md-campaigns] Delete Item Action',
  CAMPAIGNS_DELETE_ITEM_SUCCESS_ACTION = '[vw-md-campaigns] Delete Item Success Action',
  CAMPAIGNS_DELETE_ITEM_FAILURE_ACTION = '[vw-md-campaigns] Delete Item Failure Action',

}

//#region [Payloads]

export interface ILoadDataPayload {
  request: IDataRequestParams;
}
export interface ILoadDataSuccessPayload {
  summary: ICampaignsSummary;
}
export interface ILoadDataFailurePayload { }

// -------------------------------------------------------------------------- //

export interface ILoadSelectionSummaryPayload {
  selectionFilter: IQueryFilterModel;
}
export interface ILoadSelectionSummarySuccessPayload {
  summary: ISelectionSummary;
}
export interface ILoadSelectionSummaryFailurePayload { }

export interface IResetSelectionSummaryPayload { }

// -------------------------------------------------------------------------- //

export interface ICreateCampaignPayload {
  campaignItem: ICampaign; // 'category_cod_campanha'
}
export interface ICreateCampaignSuccessPayload { }
export interface ICreateCampaignFailurePayload { }

// -------------------------------------------------------------------------- //

export interface IEditCampaignPayload { }
export interface IEditCampaignSuccessPayload { }
export interface IEditCampaignFailurePayload { }

// -------------------------------------------------------------------------- //

export interface IStartBulkDeleteCampaignPayload {
  selectionFilter: IQueryFilterModel;
}
export interface IStartBulkDeleteCampaignSuccessPayload {
  bulkActionSummary: IBulkActionSummary;
}
export interface IStartBulkDeleteCampaignFailurePayload { }

// -------------------------------------------------------------------------- //

export interface IBulkDeleteCampaignPayload {
  selectionFilter: IQueryFilterModel;
}
export interface IBulkDeleteCampaignSuccessPayload { }
export interface IBulkDeleteCampaignFailurePayload { }

// -------------------------------------------------------------------------- //

export interface IDeleteCampaignPayload {
  campaignId: string;
}
export interface IDeleteCampaignSuccessPayload { }
export interface IDeleteCampaignFailurePayload { }

//#endregion

//#region [Actions]

export class LoadDataAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_LOAD_DATA_ACTION;
  constructor(public payload: ILoadDataPayload) { }
}
export class LoadDataSuccessAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_LOAD_DATA_SUCCESS_ACTION;
  constructor(public payload: ILoadDataSuccessPayload) { }
}
export class LoadDataFailureAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_LOAD_DATA_FAILURE_ACTION;
  constructor(public payload: ILoadDataFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class LoadSelectionSummaryAction {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_LOAD_SELECTION_SUMMARY_ACTION;
  constructor(public payload: ILoadSelectionSummaryPayload) { }
}
export class LoadSelectionSummarySuccessAction {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_LOAD_SELECTION_SUMMARY_SUCCESS_ACTION;
  constructor(public payload: ILoadSelectionSummarySuccessPayload) { }
}
export class LoadSelectionSummaryFailureAction {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_LOAD_SELECTION_SUMMARY_FAILURE_ACTION;
  constructor(public payload: ILoadSelectionSummaryFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class ResetSelectionSummaryAction {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_RESET_SELECTION_SUMMARY_ACTION;
  constructor(public payload: IResetSelectionSummaryPayload) { }
}

// -------------------------------------------------------------------------- //

export class CreateCampaignAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_CREATE_ITEM_ACTION;
  constructor(public payload: ICreateCampaignPayload) { }
}

export class CreateCampaignSuccessAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_CREATE_ITEM_SUCCESS_ACTION;
  constructor(public payload: ICreateCampaignSuccessPayload) { }
}

export class CreateCampaignFailureAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_CREATE_ITEM_FAILURE_ACTION;
  constructor(public payload: ICreateCampaignFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class EditCampaignAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_EDIT_ITEM_ACTION;
  constructor(public payload: IEditCampaignPayload) { }
}

export class EditCampaignSuccessAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_EDIT_ITEM_SUCCESS_ACTION;
  constructor(public payload: IEditCampaignSuccessPayload) { }
}

export class EditCampaignFailureAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_EDIT_ITEM_FAILURE_ACTION;
  constructor(public payload: IEditCampaignFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class StartBulkDeleteCampaignAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_START_BULK_DELETE_ACTION;
  constructor(public payload: IStartBulkDeleteCampaignPayload) { }
}

export class StartBulkDeleteCampaignSuccessAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_START_BULK_DELETE_SUCCESS_ACTION;
  constructor(public payload: IStartBulkDeleteCampaignSuccessPayload) { }
}

export class StartBulkDeleteCampaignFailureAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_START_BULK_DELETE_FAILURE_ACTION;
  constructor(public payload: IStartBulkDeleteCampaignFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class BulkDeleteCampaignAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_BULK_DELETE_ACTION;
  constructor(public payload: IBulkDeleteCampaignPayload) { }
}

export class BulkDeleteCampaignSuccessAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_BULK_DELETE_SUCCESS_ACTION;
  constructor(public payload: IBulkDeleteCampaignSuccessPayload) { }
}

export class BulkDeleteCampaignFailureAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_BULK_DELETE_FAILURE_ACTION;
  constructor(public payload: IBulkDeleteCampaignFailurePayload) { }
}

// -------------------------------------------------------------------------- //

export class DeleteCampaignAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_DELETE_ITEM_ACTION;
  constructor(public payload: IDeleteCampaignPayload) { }
}

export class DeleteCampaignSuccessAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_DELETE_ITEM_SUCCESS_ACTION;
  constructor(public payload: IDeleteCampaignSuccessPayload) { }
}

export class DeleteCampaignFailureAction implements Action {
  readonly type = enCampaignsActionTypes.CAMPAIGNS_DELETE_ITEM_FAILURE_ACTION;
  constructor(public payload: IDeleteCampaignFailurePayload) { }
}

//#endregion

export type vwMdCampaignsActions =
  | LoadDataAction
  | LoadDataSuccessAction
  | LoadDataFailureAction

  | LoadSelectionSummaryAction
  | LoadSelectionSummarySuccessAction
  | LoadSelectionSummaryFailureAction
  | ResetSelectionSummaryAction

  | CreateCampaignAction
  | CreateCampaignSuccessAction
  | CreateCampaignFailureAction

  | EditCampaignAction
  | EditCampaignSuccessAction
  | EditCampaignFailureAction

  | StartBulkDeleteCampaignAction
  | StartBulkDeleteCampaignSuccessAction
  | StartBulkDeleteCampaignFailureAction

  | BulkDeleteCampaignAction
  | BulkDeleteCampaignSuccessAction
  | BulkDeleteCampaignFailureAction

  | DeleteCampaignAction
  | DeleteCampaignSuccessAction
  | DeleteCampaignFailureAction

  ;
