import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { Store, Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SearchResponse, UpdateDocumentParams } from 'elasticsearch';

import {
  TvcDataModelService,
  IDataRequestParams,
  switchResponse,
  ISwitchResponse,
  ITryCatchError,
  IQueryFilterModel,
  IProductDocument,
  enDataState,
  IUpdateScript,
  IEsPartUpdateDocumentResponse,
  IElasticSearchDocument,
  TvcDataStateService,
  TvcAuthService,
  User,
  TvcUserNotificationService,
  TvcMessageHubService,
} from 'ui-components';

import {
  IProductsSummary,
  IProductsSummaryResponse,
  MasterDataProductFieldMapping,
  IMasterDataProduct,
  IMasterDataProductDocument,
} from './vw-md-products.model';
import { IProductsState } from './store/vw-md-products.state';
import {
  ILoadDataFailurePayload,
  LoadDataAction,
  LoadDataSuccessAction,
  ILoadDataSuccessPayload,
  LoadDataFailureAction,
  UpdateProductAction,
  IUpdateProductFailurePayload,
  UpdateProductFailureAction,
  IUpdateProductSuccessPayload,
  UpdateProductSuccessAction,
  IUpdateProductPayload,
} from './store/vw-md-products.actions';



@Injectable({
  providedIn: 'root'
})
export class VwMdProductsService extends TvcDataModelService {

  //#region [Local Variables]

  /**
   * Index name of vw md products service
   */
  public readonly indexName = 'idx_products';

  /**
   * Document type of vw md products service
   */
  public readonly documentType = '_doc';

  /**
   * Field mapping of vw md products service
   */
  public fieldMapping: any = new MasterDataProductFieldMapping();

  /**
   * Data state service of vw md products service
   */
  public dataStateService: TvcDataStateService<IMasterDataProduct, IMasterDataProductDocument>;

  /**
   * Notification service of vw md products service
   */
  public notificationService: TvcUserNotificationService<IMasterDataProduct, IMasterDataProductDocument>;

  //#endregion

  //#region [Constructor]

  /**
   * Creates an instance of vw md products service.
   * @param store
   */
  constructor(
    public store: Store<IProductsState>,
    private messageHubService: TvcMessageHubService,
    private authService: TvcAuthService
  ) {
    super();

    this.initialize();
    this.setupNotificationService();
    this.setupDataStateService();
  }

  //#endregion

  //#region [Properties]

  /**
   * Gets summary aggregation
   */
  public get summaryAggregation() {
    return {
      totalItemsCount: {
        value_count: {
          field: '_id'
        }
      }
    };
  }

  /**
   * Gets context filters
   */
  public get contextFilters(): Observable<IQueryFilterModel>[] {
    return [];
  }

  public get currentUser(): User {
    return this.authService.getCurrentUser();
  }
  //#endregion

  //#region [Private Methods]

  /**
   * Setups data state service
   */
  private setupDataStateService() {
    this.dataStateService = new TvcDataStateService(
      this.authService
    );

    this.dataStateService.setup(
      this,
      this.notificationService
    );
  }

  /**
   * Setups notification service
   */
  private setupNotificationService() {
    this.notificationService = new TvcUserNotificationService(
      this.dmParser,
      this.messageHubService,
      this.authService
    );
  }

  /**
   * Prepares load data query
   * @param request
   * @returns load data query
   */
  private prepareLoadDataQuery(request: IDataRequestParams): Observable<IDataRequestParams> {

    if (!request.sort.length) {
      request.sort.push({ colId: '_id', sort: 'asc' });
    }

    request._source = this.displayedFields;

    request.esAggs = Object.assign(
      request.esAggs || {},
      this.summaryAggregation
    );

    const aggQuery: IQueryFilterModel = request.aggQuery as IQueryFilterModel;

    return this.applyContextFilterInQuery(request, aggQuery);
  }

  /**
   * Composes summary
   * @param response
   * @returns summary
   */
  private composeSummary(response: SearchResponse<IProductDocument>): IProductsSummary {
    try {

      const aggs: IProductsSummaryResponse = response.aggregations;

      const summary: IProductsSummary = {
        totalItemsCount: aggs.totalItemsCount.value
      };
      return summary;

    } catch (error) {
      return {
        totalItemsCount: 0
      };
    }
  }

  /**
   * Updates product and notify
   * @param product
   * @param [newState]
   * @returns product and notify
   */
  private updateProductAndNotify(product: IMasterDataProduct, newState: enDataState = enDataState.idle): Observable<boolean> {
    const changedFields: string[] = this.getDataFieldsChanged(product);
    const editableFields: string[] = this.displayedNames;
    const fieldsToUpdate: string[] = changedFields.filter(
      (field: string) => editableFields.includes(field)
    );
    const changes: any = fieldsToUpdate.reduce((acc: any, field: string) => {
      const value: any = (product as any)[field];
      acc[field] = value;
      return acc;
    }, {});

    const values: {[field: string]: string } = {
      ...changes,
      dataState: newState,
      lockedUser: this.currentUser.username,
      updatedBy: this.currentUser.username,
      updatedIn: moment().format('YYYY-MM-DD')
    };

    const updatedFieldscript: IUpdateScript = this.dmParser.composeUpdateScript(values);

    const params: UpdateDocumentParams = {
      id: product.id,
      _source: true,
      index: this.indexName,
      type: this.documentType,
      body: {
        script: updatedFieldscript
      }
    };

    return this.update(params).pipe(
      mergeMap((response: IEsPartUpdateDocumentResponse<IMasterDataProductDocument>) => {
        const doc: IElasticSearchDocument<IMasterDataProductDocument> = this.dmParser.convertUpdateResponseToDocument(response);
        return this.dataStateService.switchChangeStateNotification(doc, newState);
      })
    );
  }
  //#endregion

  //#region [Actions]

  /**
   * Loads data action
   * @param action
   * @returns data action
   */
  public loadDataAction(action: LoadDataAction): Observable<any> {

    const parser = (data: any) => this.dmParser.fromEs(data);
    return this.prepareLoadDataQuery(action.payload.request).pipe(
      mergeMap((request: IDataRequestParams) => {
        return this.searchDataModel(request).pipe(
          switchResponse(request, { parser })
        );
      })
    );
  }

  /**
   * Dispatchs load data success
   * @param source
   * @returns load data success
   */
  public dispatchLoadDataSuccess(source: ISwitchResponse<IProductDocument>): Action {

    const summary: IProductsSummary = this.composeSummary(source.response);
    const payload: ILoadDataSuccessPayload = { summary };

    return new LoadDataSuccessAction(payload);
  }

  /**
   * Dispatchs load data failure
   * @param failure
   * @returns load data failure
   */
  public dispatchLoadDataFailure(failure: ITryCatchError<LoadDataAction, any>): Action {

    failure.action.payload.request.failCallback();

    const failurePayload: ILoadDataFailurePayload = {};
    const failureAction: LoadDataFailureAction = new LoadDataFailureAction(failurePayload);

    return failureAction;
  }

  // -------------------------------------------------------------------------- //


  /**
   * Updates product action
   * @param action
   * @returns product action
   */
  public updateProductAction(action: UpdateProductAction): Observable<any> {
    const payload: IUpdateProductPayload = action.payload;
    return this.updateProductAndNotify(payload.productItem);
  }



  /**
   * Dispatchs update product success
   * @param response
   * @returns update product success
   */
  public dispatchUpdateProductSuccess(response: any): Action[] {
    const successPayload: IUpdateProductSuccessPayload = {};
    const successAction: UpdateProductSuccessAction = new UpdateProductSuccessAction(successPayload);
    // return successAction;
    return this.dataStateService.combineActionAndNewDataState(successAction, response._id, enDataState.idle);
  }


  /**
   * Dispatchs update product failure
   * @param failure
   * @returns update product failure
   */
  public dispatchUpdateProductFailure(failure: ITryCatchError<UpdateProductAction, any>): Action[] {
    const docId: string = failure.action.payload.productItem.id;
    const failurePayload: IUpdateProductFailurePayload = {};
    const failureAction: UpdateProductFailureAction = new UpdateProductFailureAction(failurePayload);

    return this.dataStateService.combineActionAndNewDataState(failureAction, docId, enDataState.failed);
  }

  //#endregion

}
