import { Injectable } from '@angular/core';

import { Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { SearchResponse, UpdateDocumentParams } from 'elasticsearch';

import {
  TvcDataModelService,
  IDataRequestParams,
  switchResponse,
  ISwitchResponse,
  ITryCatchError,
  IQueryFilterModel,
  IEsPartUpdateDocumentResponse,
  IElasticSearchDocument,
  TvcUserNotificationService,
  TvcMessageHubService,
  TvcAuthService,
  TvcDataStateService,
  enDataState,
  User,
  IDistributionNodeDocument,
  IEsPartUpdateDocumentParams,
} from 'ui-components';

import {
  MasterDataDistributionNodeFieldMapping,
  IDistributionNodesSummary,
  IDistributionNodesSummaryResponse,
  IMasterDataDistributionNode
} from './vw-md-distribution-nodes.model';
import {
  LoadDataAction,
  ILoadDataSuccessPayload,
  LoadDataSuccessAction,
  ILoadDataFailurePayload,
  LoadDataFailureAction,
  UpdateDistributionNodeAction,
  IUpdateDistributionNodePayload,
  IUpdateDistributionNodeSuccessPayload,
  UpdateDistributionNodeSuccessAction,
  IUpdateDistributionNodeFailurePayload,
  UpdateDistributionNodeFailureAction,
} from './store/vw-md-distribution-nodes.actions';
import {
  IDistributionNodesState,
} from './store/vw-md-distribution-nodes.state';


@Injectable({
  providedIn: 'root'
})
export class VwMdDistributionNodesService extends TvcDataModelService {

  //#region [ Local Variables ]

  /**
   * Index name of vw md distribution nodes service
   */
  public readonly indexName = 'idx_distribution_nodes';

  /**
   * Document type of vw md distribution nodes service
   */
  public readonly documentType = '_doc';

  /**
   * Field mapping of vw md distribution nodes service
   */
  public fieldMapping: any = new MasterDataDistributionNodeFieldMapping();

  /**
   * Notification service of vw md distribution nodes service
   */
  public notificationService: TvcUserNotificationService<IMasterDataDistributionNode, IDistributionNodeDocument>;

  /**
   * Data state service of vw md distribution nodes service
   */
  public dataStateService: TvcDataStateService<IMasterDataDistributionNode, IDistributionNodeDocument>;

  //#endregion

  //#region [Properties]

  /**
   * Gets summary aggegation
   */
  public get summaryAggregation() {
    return {
      totalItemsCount: {
        value_count: {
          field: '_id'
        }
      }
    };
  }

  /**
   * Gets context filters
   */
  public get contextFilters(): Observable<IQueryFilterModel>[] {
    return [];
  }

  /**
   * Gets current user
   */
  public get currentUser(): User {
    return this.authService.getCurrentUser();
  }

  //#endregion

  //#region [ Constructor ]

  /**
   * Creates an instance of vw md distribution nodes service.
   * @param store
   */
  constructor(
    public store: Store<IDistributionNodesState>,
    private messageHubService: TvcMessageHubService,
    private authService: TvcAuthService,
  ) {
    super();

    this.initialize();
    this.setupNotificationService();
    this.setupDataStateService();
  }

  //#endregion

  //#region [ Private Methods ]

  /**
   * Setups data state service
   */
  private setupDataStateService() {
    this.dataStateService = new TvcDataStateService(
      this.authService
    );

    this.dataStateService.setup(
      this,
      this.notificationService
    );
  }

  /**
   * Setups notification service
   */
  private setupNotificationService() {
    this.notificationService = new TvcUserNotificationService(
      this.dmParser,
      this.messageHubService,
      this.authService
    );
  }

  /**
   * Prepares load data
   * @param request
   * @returns load data
   */
  private prepareLoadDataQuery(request: IDataRequestParams): Observable<IDataRequestParams> {

    if (!request.sort.length) {
      request.sort.push({ colId: '_id', sort: 'asc' });
    }

    request._source = this.displayedFields;

    request.esAggs = Object.assign(
      request.esAggs || {},
      this.summaryAggregation
    );

    const aggQuery: IQueryFilterModel = request.aggQuery as IQueryFilterModel;

    return this.applyContextFilterInQuery(request, aggQuery);
  }

  /**
   * Composes summary
   * @param response
   * @returns summary
   */
  private composeSummary(response: SearchResponse<IDistributionNodeDocument>): IDistributionNodesSummary {
    try {

      const aggs: IDistributionNodesSummaryResponse = response.aggregations;

      const summary: IDistributionNodesSummary = {
        totalItemsCount: aggs.totalItemsCount.value
      };
      return summary;

    } catch (error) {
      return {
        totalItemsCount: 0
      };
    }
  }


  /**
   * Updates distribution node doc
   * @param distributionNode
   * @returns distribution node doc
   */
  private updateDistributionNodeDoc(distributionNode: IMasterDataDistributionNode): Observable<IEsPartUpdateDocumentResponse<any>> {
    const newState: enDataState = enDataState.idle;
    const request: IEsPartUpdateDocumentParams = {
      doc: {
        code: distributionNode.code,
        name: distributionNode.name,
        fullname: distributionNode.fullname,
        category: distributionNode.category,
        dataRating: distributionNode.dataRating,
        leadtime: distributionNode.leadtime,
        role: distributionNode.role,
        activeState: distributionNode.activeState,

        address: {
          state: distributionNode.addressState,
          city: distributionNode.addressCity,
          street: distributionNode.addressStreet,
          number: distributionNode.addressNumber,
          cep: distributionNode.addressCep,
          district: distributionNode.addressDistrict
        },

        dataState: newState,
        lockedUser: this.currentUser.username,
        updatedBy: this.currentUser.username

      }
    };
    return this.updateDataModel(distributionNode.id, request, true);
  }

  //#endregion

  //#region [ Actions ]

  /**
   * Loads data action
   * @param action
   * @returns data action
   */
  public loadDataAction(action: LoadDataAction): Observable<any> {

    const parser = (data: any) => this.dmParser.fromEs(data);
    return this.prepareLoadDataQuery(action.payload.request).pipe(
      mergeMap((request: IDataRequestParams) => {
        return this.searchDataModel(request).pipe(
          switchResponse(request, { parser })
        );
      })
    );
  }

  /**
   * Dispatchs load data success
   * @param response
   * @returns
   */
  public dispatchLoadDataSuccess(source: ISwitchResponse<IDistributionNodeDocument>): Action {

    const summary: IDistributionNodesSummary = this.composeSummary(source.response);
    const payload: ILoadDataSuccessPayload = { summary };

    return new LoadDataSuccessAction(payload);
  }

  /**
   * Dispatchs load data failure
   * @param error
   * @returns
   */
  public dispatchLoadDataFailure(failure: ITryCatchError<LoadDataAction, any>): Action {

    failure.action.payload.request.failCallback();

    const failurePayload: ILoadDataFailurePayload = {};
    const failureAction: LoadDataFailureAction = new LoadDataFailureAction(failurePayload);

    return failureAction;
  }

  // -------------------------------------------------------------------------- //

  
  /**
   * Updates distribution node action
   * @param action 
   * @returns distribution node action 
   */
  public updateDistributionNodeAction(action: UpdateDistributionNodeAction): Observable<any> {
    const payload: IUpdateDistributionNodePayload = action.payload;
    return this.updateDistributionNodeDoc(payload.distributionNodeItem).pipe(
      mergeMap((response: IEsPartUpdateDocumentResponse<any>) => {
        const doc: IElasticSearchDocument<IDistributionNodeDocument> = { _id: response._id, ...response.get };
        return this.notificationService.notifyRecordUpdated(doc).pipe(
          map((notified: boolean) => ({
            ...response
          }))
        );
      })
    );
  }


 
  /**
   * Dispatchs update distribution node success
   * @param response 
   * @returns update distribution node success 
   */
  public dispatchUpdateDistributionNodeSuccess(response: any): Action[] {
    const successPayload: IUpdateDistributionNodeSuccessPayload = {};
    const successAction: UpdateDistributionNodeSuccessAction = new UpdateDistributionNodeSuccessAction(successPayload);
    // return successAction;
    return this.dataStateService.combineActionAndNewDataState(successAction, response._id, enDataState.idle);
  }

 
  /**
   * Dispatchs update distribution node failure
   * @param failure 
   * @returns update distribution node failure 
   */
  public dispatchUpdateDistributionNodeFailure(failure: ITryCatchError<UpdateDistributionNodeAction, any>): Action[] {
    const docId: string = failure.action.payload.distributionNodeItem.id;
    const failurePayload: IUpdateDistributionNodeFailurePayload = {};
    const failureAction: UpdateDistributionNodeFailureAction = new UpdateDistributionNodeFailureAction(failurePayload);
    return this.dataStateService.combineActionAndNewDataState(failureAction, docId, enDataState.failed);
  }

  //#endregion
}
