import {
  MemoizedSelector,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import {
  IDataRequestParams,
  IQueryFilterModel,
  IModalWindowState,
} from 'ui-components';

import {
  ICampaignsSummary,
  ISelectionSummary,
  IBulkActionSummary,
} from '../vw-md-campaigns.model';
import {
  ICampaignsState,
} from './vw-md-campaigns.state';

//#region [Initial state]

export const getCampaignsState: MemoizedSelector<object, ICampaignsState> =
  createFeatureSelector<ICampaignsState>('campaigns');

//#endregion

//#region [Internal constants]

const getDataGridQueryParams = (state: ICampaignsState): IDataRequestParams => state.dataGridQueryParams;
const getDataGridFilterCondition = (state: IDataRequestParams): IQueryFilterModel => state.query as IQueryFilterModel;

// --------------------------------------------------------------------------------------------------- //
const getBulkActionSummary = (state: ICampaignsState): IBulkActionSummary => state.bulkActionSummary;

const getSummary = (state: ICampaignsState): ICampaignsSummary => state.summary;
const getSelectionSummary = (state: ICampaignsState): ISelectionSummary => state.selectionSummary;
const getSelectedItemsCount = (state: ISelectionSummary): number => state.selectedItemsCount;
const getSelectionSummaryOnLoading = (state: ISelectionSummary): boolean => state.isLoading;
const getSelectionSummaryOnError = (state: ISelectionSummary): boolean => state.onError;

// --------------------------------------------------------------------------------------------------- //

const getCreationCampaignsModalState = (state: ICampaignsState): IModalWindowState => state.creationModalState;

const getBulkDeleteModalState = (state: ICampaignsState): IModalWindowState => state.bulkDeleteModalState;

//#endregion

//#region [Public selectors]

export const getCampaignsDataGridQueryParams: MemoizedSelector<ICampaignsState, IDataRequestParams> =
  createSelector(getCampaignsState, getDataGridQueryParams);

export const getCampaignsDataGridFilterCondition: MemoizedSelector<any, IQueryFilterModel> =
  createSelector(getCampaignsDataGridQueryParams, getDataGridFilterCondition);

export const getCampaignsBulkActionSummary: MemoizedSelector<ICampaignsState, IBulkActionSummary> =
  createSelector(getCampaignsState, getBulkActionSummary);

// --------------------------------------------------------------------------------------------------- //

export const getCampaignsSummary: MemoizedSelector<ICampaignsState, ICampaignsSummary> =
  createSelector(getCampaignsState, getSummary);

export const getCampaignsSelectionSummary: MemoizedSelector<ICampaignsState, ISelectionSummary> =
  createSelector(getCampaignsState, getSelectionSummary);

export const getCampaignsSelectedItemsCount: MemoizedSelector<ICampaignsState, number> =
  createSelector(getCampaignsSelectionSummary, getSelectedItemsCount);

export const getCampaignsSelectionSummaryOnLoad: MemoizedSelector<ICampaignsState, boolean> =
  createSelector(getCampaignsSelectionSummary, getSelectionSummaryOnLoading);

export const getCampaignsSelectionSummaryOnError: MemoizedSelector<ICampaignsState, boolean> =
  createSelector(getCampaignsSelectionSummary, getSelectionSummaryOnError);

export const getCampaignsBulkDeleteModalState: MemoizedSelector<ICampaignsState, IModalWindowState> =
  createSelector(getCampaignsState, getBulkDeleteModalState);

// --------------------------------------------------------------------------------------------------- //

export const getCampaignsCreationModalState: MemoizedSelector<ICampaignsState, IModalWindowState> =
  createSelector(getCampaignsState, getCreationCampaignsModalState);

//#endregion
