import { Action } from '@ngrx/store';
import { IDataRequestParams } from 'ui-components';
import { IPromotionsSummary } from '../vw-md-promotions.model';

export enum enPromotionsActionTypes {

    PROMOTION_LOAD_DATA_ACTION = '[vw-md-promotions] Load Data Action',
    PROMOTION_LOAD_DATA_SUCCESS_ACTION = '[vw-md-promotions] Load Data Success Action',
    PROMOTION_LOAD_DATA_FAILURE_ACTION = '[vw-md-promotions] Load Data Failure Action',

}

//#region [Payloads]

export interface ILoadDataPayload {
    request: IDataRequestParams;
}
export interface ILoadDataSuccessPayload {
    summary: IPromotionsSummary;
}
export interface ILoadDataFailurePayload { }

//#endregion

//#region [Actions]

export class LoadDataAction implements Action {
    readonly type = enPromotionsActionTypes.PROMOTION_LOAD_DATA_ACTION;
    constructor(public payload: ILoadDataPayload) { }
}
export class LoadDataSuccessAction implements Action {
    readonly type = enPromotionsActionTypes.PROMOTION_LOAD_DATA_SUCCESS_ACTION;
    constructor(public payload: ILoadDataSuccessPayload) { }
}
export class LoadDataFailureAction implements Action {
    readonly type = enPromotionsActionTypes.PROMOTION_LOAD_DATA_FAILURE_ACTION;
    constructor(public payload: ILoadDataFailurePayload) { }
}

//#endregion

export type vwMdPromotionsActions =
    | LoadDataAction
    | LoadDataSuccessAction
    | LoadDataFailureAction;
