import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';

import { IDataRequestParams } from 'ui-components';

import { IPromotionsState } from './vw-md-promotions.state';
import { IPromotionsSummary } from '../vw-md-promotions.model';

export const getPromotionsState: MemoizedSelector<object, IPromotionsState> =
    createFeatureSelector<IPromotionsState>('promotions');

const getDataGridQueryParams = (state: IPromotionsState): IDataRequestParams => state.dataGridQueryParams;
const getSummary = (state: IPromotionsState) => state.summary;

export const getPromotionsDataGridQueryParams:
    MemoizedSelector<IPromotionsState, IDataRequestParams> = createSelector(getPromotionsState, getDataGridQueryParams);

export const getPromotionsSummary:
    MemoizedSelector<IPromotionsState, IPromotionsSummary> = createSelector(getPromotionsState, getSummary);
