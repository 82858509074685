import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { reducers, effects } from './store';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environments/environment';
import { VwMdMasterPageModule } from './vw-md-master-page/vw-md-master-page.module';
import { GtagModule, setEnviroments, TvcContextMenuComponent } from 'ui-components';

setEnviroments({
  appVersion: environment.appVersion,
  production: environment.production,
  auth: environment.auth,
  elastic: environment.elastic,
  globalFilter: environment.globalFilter,
  messageHub: environment.messageHub,
  sidebarOptions: environment.sidebarOptions
});

@NgModule({
  declarations: [
    AppComponent,
    TvcContextMenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    VwMdMasterPageModule,

    GtagModule.forRoot(environment.gTag),

    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  providers: [],
  entryComponents: [TvcContextMenuComponent],

  bootstrap: [AppComponent]
})
export class AppModule { }
